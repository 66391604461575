import { Injectable } from '@angular/core';

import { AngularFireFunctions } from '@angular/fire/functions';
import { AUTH, EMAIL_EDIT, SESSION } from '@client/actions';
import {
  AuthCheckActionCodeFailureAction,
  AuthCheckActionCodeSuccessAction,
  AuthConfirmPasswordResetAction,
  AuthConfirmPasswordResetFailureAction,
  AuthConfirmPasswordResetSuccessAction,
  AuthCreateUserWithEmailAndPasswordAction,
  AuthCreateUserWithEmailAndPasswordFailureAction,
  AuthCreateUserWithEmailAndPasswordSuccessAction,
  AuthForgotPasswordAction,
  AuthForgotPasswordFailureAction,
  AuthForgotPasswordSuccessAction,
  AuthLogoutFailureAction,
  AuthLogoutSuccessAction,
  AuthRegisterEmailAction,
  AuthRegisterEmailFailureAction,
  AuthRegisterEmailSuccessAction,
  AuthReloadFailureAction,
  AuthReloadSuccessAction,
  AuthResetPasswordUpdateAction,
  AuthResetPasswordUpdateFailureAction,
  AuthResetPasswordUpdateSuccessAction,
  AuthSendEmailVerificationLinkFailureAction,
  AuthSendEmailVerificationLinkSuccessAction,
  AuthSignInWithEmailAndPasswordAction,
  AuthSignInWithEmailAndPasswordFailureAction,
  AuthSignInWithEmailAndPasswordSuccessAction,
  AuthUpdateEmailAction,
  AuthUpdateEmailFailureAction,
  AuthUpdateEmailSuccessAction,
  AuthUpdatePasswordAction,
  AuthUpdatePasswordFailureAction,
  AuthUpdatePasswordSuccessAction,
  AuthValidateCodeAction,
  AuthValidateCodeFailureAction,
  AuthValidateCodeSuccessAction,
  AuthValidateEmailCodeAction,
  AuthValidateEmailCodeFailureAction,
  AuthValidateEmailCodeSuccessAction
} from '@client/actions/auth-actions';
import { EmailEditClickSubmitAction } from '@client/actions/email-edit-actions';
import { SessionOobVerifyEmailAction, SessionReloadAction } from '@client/actions/session-actions';
import { AuthService } from '@client/core/services/auth.service';
import { AuthCodeInfo, ForgotPasswordForm, RegisterEmailForm, ResetPasswordForm, UpdatePasswordForm, User } from '@client/utils/shared-constants';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {Observable, of, throwError} from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Action } from '../../lib/action';

@Injectable()
export class AuthEffects {
  @Effect()
  checkCode$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.CHECK_ACTION_CODE),
    map((action: AuthValidateCodeAction) => action.payload),
    mergeMap((req: AuthCodeInfo) =>
      this.authService.checkCode(req.oobCode).pipe(
        map(() => new AuthCheckActionCodeSuccessAction(req)),
        catchError(error => of(new AuthCheckActionCodeFailureAction(error)))
      )
    )
  );
  @Effect()
  createUserWithEmailAndPassword$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.CREATE_USER_WITH_EMAIL_AND_PASSWORD),
    map((action: AuthCreateUserWithEmailAndPasswordAction) => action.payload),
    switchMap((req: RegisterEmailForm) =>
      this.authService.createUserWithEmailAndPasswordAction(req).pipe(
        map(() => new AuthCreateUserWithEmailAndPasswordSuccessAction(req)),
        catchError(error => of(new AuthCreateUserWithEmailAndPasswordFailureAction(error)))
      )
    )
  );
  @Effect()
  emailEditSubmit$: Observable<Action> = this.actions$.pipe(
    ofType(EMAIL_EDIT.CLICK_SUBMIT),
    map((action: EmailEditClickSubmitAction) => new AuthUpdateEmailAction(action.payload))
  );
  @Effect()
  forgotPassword$: Observable<any> = this.actions$.pipe(
    ofType(AUTH.FORGOT_PASSWORD),
    map((action: AuthForgotPasswordAction) => action.payload),
    switchMap((auth: ForgotPasswordForm) =>
      this.authService.forgotPassword(auth).pipe(
        map(() => new AuthForgotPasswordSuccessAction()),
        catchError(error => of(new AuthForgotPasswordFailureAction(error)))
      )
    )
  );
  @Effect()
  login$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.SIGN_IN_WITH_EMAIL_AND_PASSWORD),
    map((action: AuthSignInWithEmailAndPasswordAction) => action.payload),
    switchMap(auth =>
      this.authService.login(auth).pipe(
        switchMap(r => {
          const test = this.fns.httpsCallable('syncAccount');
          return test(null).pipe(map(() => r), catchError(e => {
            //if(e && e.code && e.code === 'messaging/failed-service-worker-registration') {
            //  return of(r);
            //}
            return throwError(e);
          }));
        }),
        map(r => new AuthSignInWithEmailAndPasswordSuccessAction(r)),
        catchError(error => of(new AuthSignInWithEmailAndPasswordFailureAction(error)))
      )
    )
  );
  @Effect({ dispatch: true })
  logout$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.LOGOUT),
    switchMap(() =>
      this.authService.logout().pipe(
        map(() => new AuthLogoutSuccessAction()),
        catchError(error => of(new AuthLogoutFailureAction(error)))
      )
    )
  );
  @Effect()
  oobVerifyEmail$: Observable<Action> = this.actions$.pipe(
    ofType(SESSION.OOB_VERIFY_EMAIL),
    map((action: SessionOobVerifyEmailAction) => new AuthValidateEmailCodeAction(action.payload))
  );
  @Effect()
  registerEmail$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.REGISTER_EMAIL),
    map((action: AuthRegisterEmailAction) => action.payload),
    switchMap((data: RegisterEmailForm) =>
      this.authService.register(data).pipe(
        map(() => new AuthRegisterEmailSuccessAction(data)),
        catchError(error => of(new AuthRegisterEmailFailureAction(error)))
      )
    )
  );
  @Effect({ dispatch: true })
  resetPasswordUpdate$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.RESET_PASSWORD_UPDATE),
    map((action: AuthResetPasswordUpdateAction) => action.payload),
    switchMap((data: ResetPasswordForm) =>
      this.authService.resetPassword(data).pipe(
        map(() => new AuthResetPasswordUpdateSuccessAction(data)),
        catchError(error => of(new AuthResetPasswordUpdateFailureAction(error)))
      )
    )
  );
  @Effect({ dispatch: true })
  sendEmailVerificationLink$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.SEND_EMAIL_VERIFICATION_LINK),
    switchMap(() =>
      this.authService.sendEmailVerificationLink().pipe(
        map(() => new AuthSendEmailVerificationLinkSuccessAction()),
        catchError(error => of(new AuthSendEmailVerificationLinkFailureAction(error)))
      )
    )
  );
  @Effect()
  submitEmailEdit$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.UPDATE_EMAIL),
    map((action: AuthUpdateEmailAction) => action.payload),
    switchMap(form =>
      this.authService.updateEmail(form).pipe(
        map(() => new AuthUpdateEmailSuccessAction(form)),
        catchError(error => of(new AuthUpdateEmailFailureAction(error)))
      )
    )
  );
  @Effect()
  updatePassword$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.UPDATE_PASSWORD),
    map((action: AuthUpdatePasswordAction) => action.payload),
    switchMap((req: UpdatePasswordForm) =>
      this.authService.updatePassword(req).pipe(
        map(() => new AuthUpdatePasswordSuccessAction(req)),
        catchError(error => of(new AuthUpdatePasswordFailureAction(error)))
      )
    )
  );
  @Effect()
  validateCode$: Observable<any> = this.actions$.pipe(
    ofType(AUTH.VALIDATE_CODE),
    map((action: AuthValidateCodeAction) => action.payload),
    mergeMap((req: AuthCodeInfo) =>
      this.authService.validateCode(req.oobCode).pipe(
        map(() => new AuthValidateCodeSuccessAction(req)),
        catchError(error => of(new AuthValidateCodeFailureAction(error)))
      )
    )
  );
  @Effect()
  validateCodeEmail$: Observable<Action | Action[]> = this.actions$.pipe(
    ofType(AUTH.VALIDATE_EMAIL_CODE),
    map((action: AuthValidateEmailCodeAction) => action.payload),
    switchMap((req: AuthCodeInfo) =>
      this.authService.validateEmailCode(req).pipe(
        mergeMap(() => [new AuthValidateEmailCodeSuccessAction(req), new SessionReloadAction()]),
        catchError(error => of(new AuthValidateEmailCodeFailureAction(error)))
      )
    )
  );
  @Effect()
  validateCodeEmailSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.VALIDATE_EMAIL_CODE_SUCCESS, AUTH.UPDATE_EMAIL_SUCCESS),
    switchMap(() =>
      this.authService.reload().pipe(
        map((user: User) => new AuthReloadSuccessAction(user)),
        catchError(error => of(new AuthReloadFailureAction(error)))
      )
    )
  );
  @Effect()
  validateConfirmResetPassword$: Observable<Action> = this.actions$.pipe(
    ofType(AUTH.CONFIRM_PASSWORD_RESET),
    map((action: AuthConfirmPasswordResetAction) => action.payload),
    switchMap((req: { code: string; newPassword: string }) =>
      this.authService.confirmPasswordReset(req).pipe(
        map(() => new AuthConfirmPasswordResetSuccessAction(req)),
        catchError(error => of(new AuthConfirmPasswordResetFailureAction(error)))
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<any>, private authService: AuthService, private fns: AngularFireFunctions) {}
}
