import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppInitAction } from '@client/actions/app-actions';
import { OrganizationsService } from '@client/core/services/organization.service';
import { UserService } from '@client/core/services/user.service';
import { Actions, Effect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DialogService } from '@progress/kendo-angular-dialog';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

@Injectable()
export class ScopeEffects {
  @Effect({ dispatch: true })
  init$ = this.actions$.pipe(
    ofType(ROOT_EFFECTS_INIT),
    map(() => {
      let scope = localStorage.getItem('selectedOrganization');
      if(scope) {
       return { type: 'INIT_SCOPE',  payload: { isScope: true, scope, hostname: document.location.hostname } };
      }

      scope = document.location.hostname.split('.')[0].toUpperCase();

      const domain = document.location.hostname
        .split('.')
        .splice(1)
        .join('.');

      let isScope = document.location.hostname === 'abonnement.spaq.com' || document.location.hostname === 'saaq.spaq.com' || document.location.hostname === 'saaq.passerelle.production.spaq.app' || document.location.hostname === 'cisssgaspesie.spaq.com' || document.location.hostname === 'ccsmtl.spaq.com' || document.location.hostname === 'ciusssemtl.spaq.com' || document.location.hostname === 'ciussse-chus.spaq.com' || document.location.hostname === 'cisssmo.spaq.com'  || document.location.hostname === 'cssdm.spaq.com' || document.location.hostname === 'staging.spaq.app' || document.location.hostname === 'staging.spaq.app' || domain === 'passerelle.staging.spaq.app' || domain === 'dev.spaq.app';

      // Force initial hosts to become CIUSSSE
      if (scope === 'STAGING' || scope === 'ABONNEMENT') {
        scope = 'CIUSSSE';
        isScope = true;
      }
      if (scope === 'PUBLIC') {
        scope = 'PUBLIC';
        isScope = true;
      }

      if (scope === 'CIUSSSEMTL') {
        scope = 'CIUSSSEMTL';
        isScope = true;
      }
      if (scope === 'CIUSSSE-CHUS') {
        scope = 'CIUSSSE-CHUS';
        isScope = true;
      }
      if (scope === 'CCSMTL') {
        scope = 'CCSMTL';
        isScope = true;
      }
      if (scope === 'CISSSGASPESIE') {
        scope = 'CISSSGASPESIE';
        isScope = true;
      }
      if (scope === 'CISSSMO') {
        scope = 'CISSSMO';
        isScope = true;
      }
      if (scope === 'CSSDM') {
        scope = 'CSSDM';
        isScope = true;
      }

      if(scope && (scope === 'LOCALHOST' || scope.substr(0, 4) === 'TASK')) {
      //  scope = 'CIUSSSEMTL';
      //  isScope = true;
      }
      return { type: isScope ? 'INIT_SCOPE' : 'SKIP_SCOPE', payload: { isScope, scope, hostname: document.location.hostname } };
    })
  );
  @Effect({ dispatch: true })
  initScope$ = this.actions$.pipe(
    ofType('INIT_SCOPE'),
    switchMap((action: any) =>
      this.organizationService.getOrganizationConfig(action.payload.scope).pipe(
        map(result => {
          if (result === null || typeof result !== 'object') {
            return { type: 'INIT_SCOPE_FAILED', payload: { code: 'not_found' } };
          }
          if (result.enableGateway !== true) {
            return { type: 'INIT_SCOPE_FAILED', payload: { code: 'not_allowed' } };
          }
          return { type: 'INIT_SCOPE_SUCCESS', payload: { ...action.payload, config: result } };
        }),
        catchError(e => of({ type: 'INIT_SCOPE_FAILED', code: 'not_found', message: JSON.stringify(e) }))
      )
    )
  );
  @Effect({ dispatch: false })
  initScopeFailed$ = this.actions$.pipe(
    ofType('INIT_SCOPE_FAILED'),
    exhaustMap((action: any) => {
      if (!action.payload || !action.payload.code) {
        return EMPTY;
      }
      if (action.payload.code === 'not_found') {
        return this.router.navigate(['/auth/error/not_found']);
      }
      if (action.payload.code === 'not_allowed') {
        return this.router.navigate(['/auth/error/not_allowed']);
      }
      return EMPTY;
    })
  );
  @Effect({ dispatch: true })
  initScopeSuccess$ = this.actions$.pipe(
    ofType('INIT_SCOPE_SUCCESS', 'SKIP_SCOPE'),
    map((action: any) => new AppInitAction(action.payload, new Date().getTime()))
  );

  constructor(private actions$: Actions, private dialogService: DialogService, private user: UserService, private router: Router, private store: Store<any>, private organizationService: OrganizationsService) {}
}
