import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'plancton-message-state',
  template: `
    <div *ngIf="isVisible" class="p-4" [ngClass]="{ 'alert-success': isSuccess, 'alert-danger': isError }" [@myAnimation]>
      <span *ngIf="message === 'The email address is already in use by another account.' else msg">
        L\'adresse électronique est déjà utilisée. Cliquez sur <b>Retour au formulaire</b> de connexion pour vous connecter.
      </span>
      <span #msg>{{ message }}</span>
      <span *ngIf="message !== 'The email address is already in use by another account.'"></span>
    </div>
  `,
  styles: [],
  animations: [trigger('myAnimation', [transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))]), transition(':leave', [style({ opacity: 1 }), animate('500ms', style({ opacity: 0 }))])])],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageStateComponent implements OnInit {
  get isError() {
    return this.enableError && !!this.error;
  }

  get isSuccess() {
    return this.enableSuccess && !!this.success;
  }

  get isVisible() {
    return this.success || this.error || false;
  }

  get message() {
    return this.isSuccess ? this.success : this.error && this.error.trim('\"');
  }

  @Input() enableError = true;
  @Input() enableSuccess = true;
  @Input() error = null;
  @Input() success = null;

  constructor() {}

  ngOnInit() {}
}
