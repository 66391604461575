import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";

@Injectable()
export class ConfigService {
  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) {}

  hydrate(): Observable<any> {
    return this.db.object('/config').valueChanges();
  }
  getCurrentDate() {
    return this.db.object('/upload/CardholderReport').valueChanges()
  }
  setCurrentDate(date) {
    return this.db.object('/upload/CardholderReport').set(date);
  }
}
