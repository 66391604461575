import {Component, forwardRef, ViewChild, AfterViewInit} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { AbstractInput } from '@client/shared/components/forms/abstract-input';
import {TextBoxContainerComponent} from "@progress/kendo-angular-inputs";
import {getConfigByScope} from "@client/utils/functions";

@Component({
  selector: 'plancton-input-email',
  template: `
    <kendo-textbox-container class="w-100" for="login-email-email" floatingLabel="{{ 'SHARED.FORMS.INPUT_EMAIL.LABEL' | translate }}">
      <input [id]="formControlName" [name]="formControlName" [disabled]="disabled" [autofocus]="true" [autocomplete]="true" autocomplete="email" type="email" kendoTextBox [(ngModel)]="value" (ngModelChange)="updateModel()" (blur)="onModelTouched()" />

      <small class="alert-danger" *ngIf="!isValid">
        {{ control.hasError('email') ? ('SHARED.FORMS.INPUT_EMAIL.IS_INVALID' | translate) : '' }}
        {{ control.hasError('domain') ? "Cette adresse courriel n'est pas permise. Merci d'utiliser une addresse se terminant par " + domains : '' }}
      </small>

    </kendo-textbox-container>
  `,
  styles: [],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputEmailComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => InputEmailComponent), multi: true }
  ]
})
export class InputEmailComponent extends AbstractInput implements AfterViewInit {
  @ViewChild(TextBoxContainerComponent) container;

  validateFn = (control: AbstractControl) => {
    if (control.value.length) {
      const emailValid = Validators.email(control);

      if (emailValid === null) {
        const domain = control.value.split('@')[1];

        const email = getConfigByScope('allowRegistrationDomains');

        if (email && email.length) {
          if (email.includes(domain)) {
            return null;
          }
          return {domain: true};
        }
      } else {
        return emailValid;
      }
    }

    return null;
  }
  get domains() {
    return '@' + getConfigByScope('allowRegistrationDomains').filter(x => x !== 'spaq.com').join(', @');
  }
  ngAfterViewInit() {
    window.setTimeout(() => this.container.focused = true, 0);
  }
  validate(control: AbstractControl): ValidationErrors | null {
    return this.validateFn(control);
  }
}
