export const contractService = `
Conditions de service: ENGAGEMENTS
1. J’ai pris connaissance et je m’engage à respecter la Politique de gestion du parc de stationnement et mobilité durable en vigueur dans les stationnements gérés par SPAQ et disponible sur https://spaq.com

   Je comprends qu’à la réception de mon courriel d’approbation de ma demande d’abonnement, je dois attendre un délai de 12h avant de pouvoir garer mon véhicule.
2. ATTENTION : je comprends que dans les installations qui ne sont pas munies de barrière que la vérification de la validité de mon permis se fera à l’aide de ma vignette et des informations enregistrées de mon ou de mes véhicules. Je comprends également que je dois enregistrer mon ou mes véhicules à mon compte client de la plateforme Web de la Société Parc-Auto du Québec (SPAQ) ainsi que tout changement de véhicule, et qu’un délai de 1 jour ouvrable est requis avant de rendre effectif ce changement ou cet ajout. De plus, le cas échéant, si mon permis me donne l’accès à un parc de stationnement qui n’est pas munies de barrière, je dois aviser le service à la clientèle de la SPAQ advenant l’utilisation temporaire d’un véhicule de courtoisie ou de location. Je comprends que mon permis de stationnement n’est pas transférable d’une personne à l’autre, il n’est pas permis d’utiliser des véhicules différents en même temps, et ce, même si plusieurs véhicules sont enregistrés à mon compte. La signalisation en vigueur doit être respectée en tout temps. Je comprends qu’en cas de non-respect du présent contrat, de la politique, des règlements et des procédures s’y rattachant je m’expose à recevoir une contravention, un avis de réclamation ou être remorqué.
3. Je comprends que ce permis donne un accès uniquement au parc de stationnement dans lequel je suis abonné, et qu'il ne garantit pas d’espace de stationnement, puisqu'il ne s’agit pas de l’achat d’une place de stationnement.
4. Je comprends que si je désire annuler mon contrat, je dois le faire en conformité avec la politique de SPAQ. Je comprends que je dois transmettre ma demande d’annulation via mon compte client de la plateforme Web de la SPAQ. La demande d'annulation doit être reçue par la SPAQ entre le 1er et le 15 du mois, pour une date d’annulation le dernier jour du mois en cours.  Si la demande d’annulation est reçue par la SPAQ APRÈS le 15, la date d’annulation sera le dernier jour du mois suivant.  À noter, dans le cas de fermeture, de changement de vocation ou de perte de contrat de gestion, SPAQ se réserve le droit d’annuler le présent contrat avec un préavis de 30 jours.
5. Document d’accès(carte, vignette, plaque d’immatriculation): Dans les installations qui ne sont pas munies de barrière, je comprends que je dois apposer ma vignette bien en évidence à l’endos du rétroviseur intérieur de mon véhicule et être visible en tout temps de l’extérieur du véhicule

   Un document d’accès n’est valide que pendant la période déterminée par le service sélectionné dans mon abonnement

   Des frais de 10$ seront facturés pour le remplacement de votre document d’accès. Aucun frais ne sera facturé en cas de défectuosité de la carte en autant qu’elle soit remise en main propre durant les heures d’ouverture dans les guérites de SPAQ:

   Ste-Foy: entre 7h30 et 15h<br />
   Youville: 24h/7<br />
   Hôtel de villede Québec: 24h/7<br />
   Hôtel- Dieu de Québec: 24h/7<br />
   Marie-Guyart: 7h30 à 15h30<br />
   Palais de justice de Québec: 7h30 à 15h<br />

6. SPAQ se conforme à la Loi sur l'accès aux documents des organismes publics et sur la protection des renseignements personnels, et je consens à SPAQ le droit d’utiliser ces dits renseignements ainsi que les données de mon abonnement à l’exclusion des informations bancaires relatives au paiement, uniquement dans le cadre de ses opérations en relation avec mon contrat d’abonnement au stationnement dans lequel je suis abonné.
7. SPAQ ne peut être tenu responsable des dommages causés aux véhicules et aux accessoires. De plus, il ne peut être tenu responsable des vols de véhicules, de leur contenu et des pertes de quelque nature que ce soit dans les parcs de stationnement.
8. Dans le cas d’un permis de soir, je comprends que je peux utiliser mon permis à compter de 15 h 30 jusqu’à 5 h 30 le lendemain matin. Je comprends qu’en cas de non-respect du présent contrat, de la politique, des règlements et des procédures s’y rattachant je m’expose à recevoir une contravention ou un avis de réclamation.
9. J’accepte les termes du présent contrat, de la politique, des règlements et des procédures s’y rattachant. Je comprends qu’en cas de non-respect du présent contrat, de la politique, des règlements et des procédures s’y rattachant je m’expose à recevoir une contravention ou un avis de réclamation ou à la résiliation de mon permis par le service des stationnements.
10. SPAQ se réserve le droit de modifier les tarifs sans préavis. Aucun remboursement n’est accordé.

`;

export const contractPaymentDas = `
###### Conditions de paiement par déduction à la source
Veillez-vous référer aux conditions indiquées au formulaire d’autorisation de déduction à la source.
`;

export const contractPaymentInternet = `
###### Conditions de paiement par Internet Bancaire

1. Le locataire doit ajouter le fournisseur « société parc-auto du Québec » à partir de la solution en ligne de son institution financière. Le numéro du contrat de location qui sera confirmé au locataire après avoir complété son inscription devra être utilisé afin d’indexer le numéro du compte;
2. Si le service choisi par le locataire est un abonnement payable le premier de chaque mois, il devra effectuer son paiement au moins cinq jours ouvrables à l’avance afin que SPAQ reçoive le paiement de sa banque. À la réception du paiement par SPAQ, un reçu sera transmis au locataire;
3. Si la solution de paiement de l’institution financière le permet, SPAQ recommande au locataire d’activer la récurrence du paiement à date fixe permettant ainsi de s’assurer que l’accès au parc de stationnement ne lui soit pas retiré advenant que SPAQ n’est pas reçu la confirmation du paiement par sa banque dans les temps requis;
4. La somme indiquée de l’abonnement est le tarif en vigueur au moment de l’acceptation du présent contrat et peut être changée sans préavis suite à un changement de tarif. Le cas échant le locataire qui aura opté pour la récurrence du paiement à date fixe devra s’assurer d’ajuster le montant de la mensualité.
5. Toute révocation de la présente autorisation doit être faite par avis écrit;
6. SPAQ n’effectue aucun remboursement au locataire;
7. Toute délivrance de la présente autorisation est réputée faite par le locataire;
8. Dans le cas d’un compte conjoint du locataire avec une autre personne, il a la responsabilité de s’assurer de l’accord des autres personnes liées au compte.
`;

export const contractPaymentPpa = `
###### Chèque PAIEMENT MENSUEL / RENOUVELLEMENT AUTOMATIQUE DU PERMIS Autorisation de paiements mensuels préautorisés et conditions

1. L’institution financière mentionnée sur le spécimen de chèque joint à la demande de permis (ci-après désignée « l’Institution financière ») est autorisée à payer et à porter au débit du compte tout effet payable à l’ordre de la « Société Parc-Auto du Québec » (ci-après désignée « la SPAQ ») tiré sur l’établissement de l’Institution financière par la banque de la SPAQ ou sur instruction directe de cette dernière.
2. Pour le traitement de chacun de ces débits, l’Institution financière procède exactement comme si l’adhérent avait à chaque fois demandé à l’Institution financière de payer la mensualité et d’en porter le montant au débit du compte de l’adhérent.
3. Le renouvellement de l’abonnement se fera automatiquement au 1er avril de chaque année à moins d’annulation de l’adhérent, et la somme indiquée de l’abonnement est le tarif en vigueur au moment de l’acceptation du présent contrat et peut être modifiée sans préavis, au 1er avril de chaque année, conformément à la Politique de gestion du parc de stationnement et mobilité durable de SPAQ.
4. L’adhérent souhaitant annuler son contrat doit le faire en conformité avec la Politique de SPAQ via son compte client de la plateforme Web de la SPAQ. La demande d'annulation doit être reçue par la SPAQ entre le 1er et le 15 du mois, pour une date d’annulation le dernier jour du mois en cours.  Si la demande d’annulation est reçue par la SPAQ APRÈS le 15, la date d’annulation sera le dernier jour du mois suivant.
5. La SPAQ n’effectue aucun remboursement au détenteur du permis.
6. La présente autorisation est réputée faite par l’adhérent.
7. La SPAQ transmettra un reçu par courriel après chaque prélèvement.
8. Des frais de 20$ seront facturés en cas de chèque sans provision.
9. Tel que stipulé à l’article 5 des conditions de service de mon contrat, des frais de 10$ seront facturés dans le cas d’un remplacement du document d’accès à un stationnement de SPAQ.
`;

export const contractPaymentCreditCard = `
###### PAIEMENT MENSUEL / RENOUVELLEMENT AUTOMATIQUE DU PERMIS Autorisation de prélèvements mensuels préautorisés et conditions

1. L’émetteur de la carte de crédit inscrite par l'adhérent (ci-après désignée « la compagnie de crédit ») est autorisé à payer et à porter au débit du compte tout effet payable à l’ordre de la « Société Parc-Auto du Québec » (ci-après désignée « la SPAQ ») tiré sur la compagnie de crédit par l'acquéreur de la SPAQ ou sur instruction directe de cette dernière.
2. Pour le traitement de chacun de ces débits, la compagnie de crédit procède exactement comme si l’adhérent avait à chaque fois demandé à la compagnie de crédit de payer la mensualité et d’en porter le montant au débit du compte de l’adhérent.
3. Le renouvellement de l’abonnement se fera automatiquement au 1er avril de chaque année, à moins d’annulation de l’adhérent, et la somme indiquée de l’abonnement est le tarif en vigueur au moment de l’acceptation du présent contrat et peut être modifiée, sans préavis, au 1er avril de chaque année, conformément à la Politique de gestion du parc de stationnement et mobilité durable de SPAQ.
4. L’adhérent souhaitant annuler son contrat doit le faire en conformité avec la Politique de SPAQ via son compte client de la plateforme Web de SPAQ. La demande d'annulation doit être reçue par la SPAQ entre le 1er et le 15 du mois, pour une date d’annulation le dernier jour du mois en cours.  Si la demande d’annulation est reçue par la SPAQ APRÈS le 15, la date d’annulation sera le dernier jour du mois suivant.
5. La SPAQ n’effectue aucun remboursement au détenteur du permis.
6. La présente autorisation est réputée faite par l’adhérent.
7. La SPAQ transmettra un reçu par courriel après chaque prélèvement.
8. Des frais de 20$ seront facturés dans le cas d’un paiement refusé.
9. Tel que stipulé à l’article 5 des conditions de service de mon contrat, des frais de 10$ seront facturés dans le cas d’un remplacement du document d’accès à un stationnement de SPAQ.
`;
