import { PARKS_SELECT, CONTRACT } from "@client/actions";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ContractInfo, ParkInfo, ServiceInfoWithGroup, SubscriptionService, ContractForm } from "@client/utils/shared-constants";
export interface State {
    message: string | null;
    hasError: boolean;
    hasSuccess: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    isPending: boolean;
    paymenRequired: boolean;
    isCancelable: boolean;
    isEnded: boolean;
    data: ContractInfo | null;
}
export const initialState: State = {
    message: null,
    hasError: false,
    hasSuccess: false,
    isLoaded: false,
    isLoading: false,
    isPending: false,
    paymenRequired: false,
    isCancelable: false,
    isEnded: false,
    data: null
};
export function reducer(state = initialState, action: any): State {
    switch (action.type) {
        case CONTRACT.HYDRATE: return contractHydrate(state);
        case CONTRACT.HYDRATE_SUCCESS: return contractHydrateSuccess(state, action.payload);
        case CONTRACT.HYDRATE_FAILURE: return contractHydrateFailure(state, action.payload);
        case CONTRACT.ADD_PARK: return contractAddPark(state, action.payload);
        case CONTRACT.ADD_PARK_SUCCESS: return contractAddParkSuccess(state);
        case CONTRACT.ADD_PARK_FAILURE: return contractAddParkFailure(state, action.payload);
        case CONTRACT.ADD_SERVICE: return contractAddService(state, action.payload);
        case CONTRACT.ADD_SERVICE_SUCCESS: return contractAddServiceSuccess(state, action.payload);
        case CONTRACT.ADD_SERVICE_FAILURE: return contractAddServiceFailure(state, action.payload);
        case CONTRACT.ADD_SUBSCRIPTION_SERVICE: return contractAddSubscriptionService(state, action.payload);
        case CONTRACT.ADD_SUBSCRIPTION_SERVICE_SUCCESS: return contractAddSubscriptionServiceSuccess(state, action.payload);
        case CONTRACT.ADD_SUBSCRIPTION_SERVICE_FAILURE: return contractAddSubscriptionServiceFailure(state, action.payload);
        case CONTRACT.ADD_PAYMENT: return contractAddPayment(state, action.payload);
        case CONTRACT.ADD_PAYMENT_SUCCESS: return contractAddPaymentSuccess(state);
        case CONTRACT.ADD_PAYMENT_FAILURE: return contractAddPaymentFailure(state, action.payload);
        case CONTRACT.ACCEPT_CONDITIONS_SERVICE: return contractAcceptConditionsService(state);
        case CONTRACT.ACCEPT_CONDITIONS_SERVICE_SUCCESS: return contractAcceptConditionsServiceSuccess(state);
        case CONTRACT.ACCEPT_CONDITIONS_SERVICE_FAILURE: return contractAcceptConditionsServiceFailure(state, action.payload);
        case CONTRACT.ACCEPT_CONDITIONS_PAYMENT: return contractAcceptConditionsPayment(state);
        case CONTRACT.ACCEPT_CONDITIONS_PAYMENT_SUCCESS: return contractAcceptConditionsPaymentSuccess(state);
        case CONTRACT.ACCEPT_CONDITIONS_PAYMENT_FAILURE: return contractAcceptConditionsPaymentFailure(state, action.payload);
        case CONTRACT.SEARCH: return contractSearch(state, action.payload);
        case CONTRACT.SEARCH_SUCCESS: return contractSearchSuccess(state, action.payload);
        case CONTRACT.SEARCH_FAILURE: return contractSearchFailure(state, action.payload);
        case CONTRACT.DELETE: return contractDelete(state);
        case CONTRACT.DELETE_SUCCESS: return contractDeleteSuccess(state);
        case CONTRACT.DELETE_FAILURE: return contractDeleteFailure(state);
        case CONTRACT.CANCEL: return contractCancel(state, action.payload);
        case CONTRACT.CANCEL_SUCCESS: return contractCancelSuccess(state, action.payload);
        case CONTRACT.CANCEL_FAILURE: return contractCancelFailure(state, action.payload);
        case CONTRACT.DELETE_SERVICE: return contractDeleteService(state, action.payload);
        case CONTRACT.DELETE_SERVICE_SUCCESS: return contractDeleteServiceSuccess(state, action.payload);
        case CONTRACT.DELETE_SERVICE_FAILURE: return contractDeleteServiceFailure(state, action.payload);
        case PARKS_SELECT.CLICK_PARK: return parksSelectClickPark(state, action.payload);
        default: return state;
    }
}
export function contractHydrate(state: State): State {
    return {
        ...state,
        isLoading: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function contractHydrateSuccess(state: State, payload: ContractInfo): State {
    let paymentRequired = false;
    if (payload && payload.isCreationCompleted && !payload.isPaid) {
            if (payload.payment !== 'DAS') {
                if (payload.service && !(payload.service.price === 0 && payload.service.serviceBillingType.toString() !== 'AtBooking')) {
                    paymentRequired = true;
                }
            }
    }
    return {
        ...initialState,
        isLoading: false,
        hasSuccess: true,
        isLoaded: true,
        paymenRequired: paymentRequired,
        isCancelable: payload && payload.service && payload.service.isCancelable,
        data: payload || initialState.data
    };
}
export function contractHydrateFailure(state: State, payload: any): State {
    return {
        ...initialState,
        isLoading: false,
        hasError: true,
        message: payload
    };
}
export function contractAddPark(state: State, payload: ParkInfo): State {
    return {
        ...state,
        isPending: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function contractAddParkSuccess(state: State): State {
    return {
        ...state,
        isPending: false,
        hasSuccess: true
    };
}
export function contractAddParkFailure(state: State, payload: any): State {
    return {
        ...state,
        isPending: false,
        hasError: true,
        message: payload
    };
}
export function contractAddService(state: State, payload: ServiceInfoWithGroup): State {
    return {
        ...state,
        isPending: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function contractAddServiceSuccess(state: State, payload: ServiceInfoWithGroup): State {
    return {
        ...state,
        isPending: false,
        hasSuccess: true
    };
}
export function contractAddServiceFailure(state: State, payload: any): State {
    return {
        ...state,
        isPending: false,
        hasError: true,
        message: payload
    };
}
export function contractAddSubscriptionService(state: State, payload: SubscriptionService): State {
    return { ...state };
}
export function contractAddSubscriptionServiceSuccess(state: State, payload: SubscriptionService): State {
    return { ...state };
}
export function contractAddSubscriptionServiceFailure(state: State, payload: any): State {
    return { ...state };
}
export function contractAddPayment(state: State, payload: string): State {
    return {
        ...state,
        isPending: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function contractAddPaymentSuccess(state: State): State {
    return {
        ...state,
        isPending: false,
        hasSuccess: true
    };
}
export function contractAddPaymentFailure(state: State, payload: any): State {
    return {
        ...state,
        isPending: false,
        hasError: true,
        message: payload
    };
}
export function contractAcceptConditionsService(state: State): State {
    return {
        ...state,
        isPending: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function contractAcceptConditionsServiceSuccess(state: State): State {
    return {
        ...state,
        isPending: false,
        hasSuccess: true
    };
}
export function contractAcceptConditionsServiceFailure(state: State, payload: any): State {
    return {
        ...state,
        isPending: false,
        hasError: true,
        message: payload
    };
}
export function contractAcceptConditionsPayment(state: State): State {
    return {
        ...state,
        isPending: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function contractAcceptConditionsPaymentSuccess(state: State): State {
    return {
        ...state,
        isPending: false,
        hasSuccess: true
    };
}
export function contractAcceptConditionsPaymentFailure(state: State, payload: any): State {
    return {
        ...state,
        isPending: false,
        hasError: true,
        message: payload
    };
}
export function contractSearch(state: State, payload: ContractForm): State {
    return {
        ...state,
        isPending: true,
        hasSuccess: false,
        hasError: false,
        message: null
    };
}
export function contractSearchSuccess(state: State, payload: ContractForm): State {
    return {
        ...state,
        isPending: false,
        hasSuccess: true
    };
}
export function contractSearchFailure(state: State, payload: any): State {
    return {
        ...state,
        isPending: false,
        hasError: true,
        message: payload
    };
}
export function contractDelete(state: State): State {
    return state;
}
export function contractDeleteSuccess(state: State): State {
    return state;
}
export function contractDeleteFailure(state: State): State {
    return state;
}
export function contractCancel(state: State, payload: string): State {
    return { ...state };
}
export function contractCancelSuccess(state: State, payload: string): State {
    return { ...state };
}
export function contractCancelFailure(state: State, payload: any): State {
    return { ...state };
}
export function contractDeleteService(state: State, payload: any): State {
    return { ...state, isPending: true, message: null, hasError: null, hasSuccess: null };
}
export function contractDeleteServiceSuccess(state: State, payload: any): State {
    return { ...state, isPending: false, hasSuccess: true };
}
export function contractDeleteServiceFailure(state: State, payload: any): State {
    return { ...state, isPending: false, hasError: true, message: payload };
}
export function parksSelectClickPark(state: State, payload: ParkInfo): State {
    return { ...state };
}
