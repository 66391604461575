import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireDatabase} from '@angular/fire/database';
import {
  getAccessCodeData,
  getContractCreateSelectedPayment,
  getContractCreateSelectedServiceId, getContractData, getContractDataGroupId, getContractDataSectorId,
  getLayoutScopeId, getOrganizationEntities
} from '@client/selectors';
import {
  ContractInfo,
  ParkInfo,
  ServiceInfo,
  ServiceInfoWithGroup,
  SubscriptionInfo
} from '@client/utils/shared-constants';
import {select, Store} from '@ngrx/store';

import {empty, EMPTY, from, Observable, of, zip} from 'rxjs';
import {map, switchMap, withLatestFrom, tap} from 'rxjs/operators';
import {
  contractPaymentCreditCard,
  contractPaymentDas,
  contractPaymentInternet,
  contractPaymentPpa,
  contractService
} from '../../configs/contract-text';
import {Service} from '@client/utils/service-model';
import firebase from 'firebase';
import User = firebase.User;
import {getCustomPickupLocations} from "@client/utils/customSelectors";

export const createService = (service: ServiceInfo): any => {
  const s = new Service(service);

  const invoiceAmount = s.partialPrice;
  const invoiceFrom = s.startDate.toISOString();
  const endDate = s.partialEndDate.toISOString();
  const invoiceTo = s.partialInvoiceToDate.toISOString();
  let approved = false;

  if (service.serviceType.toString() === 'Public' || service.serviceType.toString() === 'Private') {
    approved = true;
  }

  return {
    serviceId: service.availableServiceId,
    serviceDescription: service.serviceDescription,
    isApproved: approved,
    effectiveDate: invoiceFrom,
    endDate: endDate,
    isPaidByGroup: false,
    invoiceAmount: invoiceAmount.toString(),
    invoiceFrom: invoiceFrom,
    invoiceTo: invoiceTo
  };
};

@Injectable()
export class ContractService {
  constructor(public db: AngularFireDatabase, private auth: AngularFireAuth, private store: Store<any>) {
  }

  acceptConditionsPayment(isCheck = true): Observable<void> {
    return this.auth.user.pipe(
      switchMap((u: User) => from(this.db.object(`/user/${u.uid}/contract`).update({
        step: 1,
        reminded: null,
        lastUpdated: new Date().toISOString(),
        conditionsPaymentAcceptedAt: !isCheck ? null : new Date().toISOString()
      })))
    );
  }

  acceptConditionsService(isCheck = true): Observable<void> {
    const updatePayload = {
      step: 1,
      reminded: null,
      lastUpdated: new Date().toISOString(),
      conditionsServiceAcceptedAt: !isCheck ? null : new Date().toISOString()
    };

    if (!isCheck) {
      updatePayload['service'] = null;
    }

    return this.auth.user.pipe(
      switchMap((u: User) => from(this.db.object(`/user/${u.uid}/contract`).update(updatePayload)))
    );
  }

  acceptConditionsServiceApprobationPayment(isCheck): Observable<void> {
    const updatePayload = {
      step: 1,
      reminded: null,
      lastUpdated: new Date().toISOString(),
      paymentApprobationAcceptedAt: !isCheck ? null : new Date().toISOString()
    };

    return this.auth.user.pipe(
      switchMap((u: User) => from(this.db.object(`/user/${u.uid}/contract`).update(updatePayload)))
    );
  }
  acceptConditionsPlaceReservation(isCheck): Observable<void> {
    const updatePayload = {
      step: 1,
      reminded: null,
      lastUpdated: new Date().toISOString(),
      placesReservationAcceptedAt: !isCheck ? null : new Date().toISOString()
    };
    return this.auth.user.pipe(
      switchMap((u: User) => from(this.db.object(`/user/${u.uid}/contract`).update(updatePayload)))
    );
  }
  agreeToMove(isCheck): Observable<void> {
    const updatePayload = {
      step: 1,
      reminded: null,
      lastUpdated: new Date().toISOString(),
      agreeToMove: !isCheck ? null : true
    };
    return this.auth.user.pipe(
      switchMap((u: User) => from(this.db.object(`/user/${u.uid}/contract`).update(updatePayload)))
    );
  }

  resetExpiration(): Observable<void> {
    const updatePayload = {
      step: 1,
      lastUpdated: new Date().toISOString(),
      service: null,
      resetExpiration: true,
      serviceAddedAt: null,
      subscriptionService: null
    };
    return this.auth.user.pipe(
      switchMap((u: User) => from(this.db.object(`/user/${u.uid}/contract`).update(updatePayload)))
    );
  }

  addAccessCard(accessCard): Observable<any> {
    return this.auth.user.pipe(
      switchMap((u: User) => zip(
        this.db.object(`/user/${u.uid}/subscription/custom/numero_acces`).set(accessCard),
        this.db.object(`/user/${u.uid}/contract/subscription/custom/numero_acces`).set(accessCard),
      ))
    );
  }

  haveAccessCard(accessCard): Observable<any> {
    return this.auth.user.pipe(
      switchMap((u: User) => zip(
        this.db.object(`/user/${u.uid}/contract/haveAccessCard`).set(accessCard),
        this.db.object(`/user/${u.uid}/subscription/custom/numero_acces`).set(null),
        this.db.object(`/user/${u.uid}/contract/subscription/custom/numero_acces`).set(null)
      ))
    );
  }

  acceptWaitList(isCheck): Observable<any> {
    const updatePayload = {
      step: 1,
      reminded: null,
      lastUpdated: new Date().toISOString(),
      enableWaitingList: !isCheck ? null : true
    };

    return this.auth.user.pipe(
      switchMap((u: User) => zip(
        this.db.object(`/user/${u.uid}/contract`).update(updatePayload),
        this.db.object(`/user/${u.uid}/subscription/custom/liste_attente`).set({
          key: isCheck && '1' || '0',
          value: isCheck && 'Oui' || 'Non'
        }),
        this.db.object(`/user/${u.uid}/contract/subscription/custom/liste_attente`).set({
          key: isCheck && '1' || '0',
          value: isCheck && 'Oui' || 'Non'
        })
      ))
    );
  }

  acceptConditionsServicePayment(isCheck, payment = null): Observable<void> {
    return this.auth.user.pipe(
      switchMap((u: User) => from(this.db.object(`/user/${u.uid}/contract`).update({
        isPaid: isCheck && payment === 'Cash',
        isPaid5: isCheck && payment === 'Cash',
        step: 1,
        reminded: null,
        lastUpdated: new Date().toISOString(),
        paymentAcceptedAt: !isCheck ? null : new Date().toISOString()
      })))
    );
  }

  addPark(park: ParkInfo): Observable<any> {
    return this.auth.user.pipe(
      switchMap((u: User) => zip(
        this.db.object(`/user/${u.uid}/contract`).update({
          step: 1,
          reminded: null,
          groupId: null, sectorId: null,
          lastUpdated: new Date().toISOString(),
          park,
          service: null,
          paymentBeforeApproval: null,
          payment: null,
          subscriptionService: null,
          conditionsServiceAcceptedAt: null,
          conditionsPaymentAcceptedAt: null,
          addPark: true
        }),

        this.db.object(`/user/${u.uid}/subscription/custom`).set(null),
      ))
    );
  }

  addPayment(payment: any): Observable<void> {
    return this.auth.user.pipe(
      switchMap((u: User) => from(
        this.db.object(`/user/${u.uid}/contract`).update({
          payment,
          conditionsPaymentAcceptedAt: null,
          paymentAcceptedAt: null,
        })
      ))
    );
  }

  deleteService() {
    const serviceData = {
      step: 1,
      service: null,
      deleteService: true,
      lastUpdated: new Date().toISOString(),
      subscriptionService: null,
      paymentBeforeApproval: null,
    };

    return this.auth.user.pipe(
      switchMap((u: User) => this.db.object(`/user/${u.uid}/contract`).update(serviceData)));
  }

  addGroup(groupId: any): Observable<any> {
    return this.auth.user.pipe(
      withLatestFrom(this.store.pipe(select(getLayoutScopeId))),
      switchMap(([u, scopeId]: [User, string]) => [
        this.db.object(`/user/${u.uid}/contract`).update({
          groupId: groupId,
          sectorId: null,
          enableWaitingList: null,
          service: null
        }),
        this.db.object(`/user/${u.uid}/access-code`).update({
          groupCode: groupId,
          organizationCode: scopeId
        })
      ])
    );
  }

  addSector(sectorId: any): Observable<any> {
    return this.auth.user.pipe(
      withLatestFrom(this.store.pipe(select(getCustomPickupLocations),
        map((x: any) => x && x[sectorId.id] || null))),
      switchMap(([u, meta]: any) => from(
        [
          this.db.object(`/user/${u.uid}/contract`).update({
            sectorId: sectorId,
            service: null,
            enableWaitingList: null
          }),
          this.db.object(`/user/${u.uid}/subscription/custom/lieu_de_recuperation`).set(meta || null),
          this.db.object(`/user/${u.uid}/contract/subscription/custom/lieu_de_recuperation`).set(meta || null)
        ]
      ))
    );
  }

  addPickupLocation(pickupLocation) {
    return this.auth.user.pipe(
      switchMap((u: User) => from([
          this.db.object(`/user/${u.uid}/subscription/custom/lieu_de_recuperation`).set(pickupLocation || null),
          this.db.object(`/user/${u.uid}/contract/subscription/custom/lieu_de_recuperation`).set(pickupLocation || null)
        ]
      ))
    );
  }

  setServiceSelected(service: any): Observable<void> {
    return this.auth.user.pipe(
      switchMap((u: User) => from(
        this.db.object(`/user/${u.uid}/contract`).update({
          selectedService: service.availableServiceId,
          payment: service.serviceBillingType === 'DAS' && 'DAS' || null
        })
      ))
    );
  }

  addService(service: ServiceInfoWithGroup | any) {
    const subscriptionService = createService(service.service);

    const serviceData = {
      step: 2,
      host: window.location.hostname + ':' + window.location.port,
      reminded: null,
      lastUpdated: new Date().toISOString(),
      service: service.service,
      groupId: service.groupId || null,
      agreeToMove: null,
      haveAccessCard: null,
      sectorId: service.sectorId || null,
      subscriptionService,
      isPaid: false,
      paymentBeforeApproval: null,
      payment: (service.service.serviceBillingType === 'DAS' && 'DAS') || ((!service.service.price && service.service.serviceBillingType !== 'AtBooking') && 'Cash') || null,
      conditionsPaymentAcceptedAt: null,
      serviceAddedAt: new Date().toISOString()
    };

    return this.auth.user.pipe(
      switchMap((u: User) => {
        let updateAccessCodeWhenGroupFound = of(null);
        if (service.groupId) {
          updateAccessCodeWhenGroupFound = from(this.db.object(`/user/${u.uid}/access-code`).update({
            userCode: service.sectorId && service.sectorId.id || null,
            organizationCode: service.scopeId || '',
            groupCode: service.groupId && service.groupId.groupCode || '',
            isScope: true
          }));
        }
        return zip(
          this.db.object(`/user/${u.uid}/contract`).update(serviceData),
          updateAccessCodeWhenGroupFound
        )
      })
    );
  }

  cancelService() {
    return this.auth.user.pipe(
      switchMap((u: User) => zip(
        this.db.object(`/user/${u.uid}/contract`).update({
          isCreationCompleted: null,
          service: null,
          subscriptionService: null,
          conditionsServiceAcceptedAt: null,
          cancelService: true,
          conditionsPaymentAcceptedAt: null,
          createdAt: null,
          park: null,
          isPaid: null,
          lastUpdated: null,
          payment: null,
          step: null
        })
      ))
    );
  }

  delete() {
    return this.auth.user.pipe(
      switchMap((u: User) => zip(
        this.db.object(`/user/${u.uid}`).update({
          contract: null,
          contractNo: null
        }),

        this.db.object(`/user/${u.uid}/accountStatus/isSubscriptionRequired`).set(true),
        this.db.object(`/user/${u.uid}/vehicles`).remove()
      ))
    );
  }

  findConditionsPayment(): Observable<string> {
    return this.store.pipe(
      select(getAccessCodeData),
      withLatestFrom(this.store.pipe(select(getContractData), map((x: any) => x && x.selectedService || null)), this.store.pipe(select(getContractCreateSelectedPayment)),
        this.store.pipe(select(getLayoutScopeId)),
        this.store.pipe(select(getOrganizationEntities)),
        this.store.pipe(select(getContractDataGroupId))),
      switchMap(([accessCodeData, selectedServiceId, selectedPayment, scopeId, organizations, selectedGroupId]: any) => {
        const organization = (organizations || {})[scopeId];
        const isSelectableGroup = organization && organization.config && organization.config.enableSelectableGroup;
        let organizationData: any = {};

        if (accessCodeData) {
          organizationData = accessCodeData;
        }
        if (isSelectableGroup && organization.groups && organization.groups[selectedGroupId]) {
          organizationData = organization.groups[selectedGroupId];
        }

        if (selectedServiceId && organizationData && organizationData.services) {
          if (organizationData.services[selectedServiceId] && organizationData.meta && organizationData.meta.conditions) {
            switch (selectedPayment) {
              case 'Credit':
                if (organizationData.meta.conditions.paymentCc) {
                  return of(organizationData.meta.conditions.paymentCc);
                }
                break;
              case 'Cash':
                if (organizationData.meta.conditions.paymentInternet) {
                  return of(organizationData.meta.conditions.paymentInternet);
                }
                break;
              case 'PreAuthorized':
                if (organizationData.meta.conditions.paymentPpa) {
                  return of(organizationData.meta.conditions.paymentPpa);
                }
                break;
              case 'DAS':
                if (organizationData.meta.conditions.paymentDas) {
                  return of(organizationData.meta.conditions.paymentDas);
                }
                break;
              default:
                if (organizationData.meta.conditions.paymentInternet) {
                  return of(organizationData.meta.conditions.paymentInternet);
                }
                break;
            }
          }
        }
        switch (selectedPayment) {
          case 'Credit':
            return of(contractPaymentCreditCard);
          case 'Cash':
            return of(contractPaymentInternet);
          case 'PreAuthorized':
            return of(contractPaymentPpa);
          case 'DAS':
            return of(contractPaymentDas);
          default:
            return of(contractPaymentInternet);
        }
      })
    );
  }

  findConditionsService(): Observable<string> {
    return this.store.pipe(
      select(getAccessCodeData),
      withLatestFrom(
        this.store.pipe(select(getContractData), map((x: any) => x && x.selectedService || null)),
        this.store.pipe(select(getLayoutScopeId)),
        this.store.pipe(select(getOrganizationEntities)),
        this.store.pipe(select(getContractDataGroupId)),
      ),
      switchMap(([accessCodeData, selectedServiceId, scopeId, organizations, selectedGroupId]: any) => {

        const organization = (organizations || {})[scopeId];
        const isSelectableGroup = organization && organization.config && organization.config.enableSelectableGroup;
        let organizationData: any = {};

        if (accessCodeData) {
          organizationData = accessCodeData;
        }
        if (isSelectableGroup && organization.groups && organization.groups[selectedGroupId]) {
          organizationData = organization.groups[selectedGroupId];
        }
        if (selectedServiceId && organizationData && organizationData.services) {
          if (organizationData.services[selectedServiceId] && organizationData.meta && organizationData.meta.conditions) {
            if (organizationData.meta.conditions.service) {
              return of(organizationData.meta.conditions.service);
            }
          }
        }
        return of(contractService);
      })
    );
  }


  hydrate(): Observable<ContractInfo> {
    return this.auth.user.pipe(
      switchMap((u: User) => this.db.object<ContractInfo>(`/user/${u.uid}/contract`).valueChanges())
    );
  }

  setPaymentMode(mode: boolean): Observable<void> {
    return this.auth.user.pipe(
      switchMap((u: User) => from(this.db.object(`/user/${u.uid}/contract`).update({
        payment: mode,
        conditionsPaymentAcceptedAt: null,
        paymentAcceptedAt: null
      })))
    );
  }
}
