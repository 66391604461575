import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'spaq-layout-account-container',
  template: `
            <ng-content></ng-content>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutAccountContainerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
