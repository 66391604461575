import {Injectable} from '@angular/core';

import {AngularFireFunctions} from '@angular/fire/functions';
import {
  CONTRACT,
  CONTRACT_CANCEL,
  CONTRACT_CONDITIONS_PAYMENT,
  CONTRACT_CONDITIONS_SERVICE,
  CONTRACT_CREATE,
  PARKS_SELECT,
  PAYMENT_SELECT_METHOD,
  SERVICES_SELECT,
  SESSION,
  SUBSCRIPTION_EDIT,
  VEHICLES_ADD
} from '@client/actions';
import {
  ContractAcceptConditionsPaymentAction,
  ContractAcceptConditionsPaymentFailureAction,
  ContractAcceptConditionsPaymentSuccessAction,
  ContractAcceptConditionsServiceAction,
  ContractAcceptConditionsServiceFailureAction,
  ContractAcceptConditionsServiceSuccessAction,
  ContractAddParkAction,
  ContractAddParkFailureAction,
  ContractAddParkSuccessAction,
  ContractAddPaymentAction,
  ContractAddPaymentFailureAction,
  ContractAddPaymentSuccessAction,
  ContractAddServiceAction,
  ContractAddServiceFailureAction,
  ContractAddServiceSuccessAction,
  ContractAddSubscriptionServiceAction,
  ContractAddSubscriptionServiceFailureAction,
  ContractAddSubscriptionServiceSuccessAction,
  ContractCancelAction,
  ContractCancelFailureAction,
  ContractCancelSuccessAction,
  ContractDeleteFailureAction,
  ContractDeleteServiceAction,
  ContractDeleteServiceFailureAction,
  ContractDeleteServiceSuccessAction,
  ContractDeleteSuccessAction,
  ContractHydrateAction,
  ContractHydrateFailureAction,
  ContractHydrateSuccessAction,
  ContractSearchAction,
  ContractSearchFailureAction,
  ContractSearchSuccessAction
} from '@client/actions/contract-actions';
import {ContractCancelClickCancelAction} from '@client/actions/contract-cancel-actions';
import {
  ContractConditionsPaymentHydrateFailureAction,
  ContractConditionsPaymentHydrateSuccessAction
} from '@client/actions/contract-conditions-payment-actions';
import {
  ContractConditionsServiceHydrateFailureAction,
  ContractConditionsServiceHydrateSuccessAction
} from '@client/actions/contract-conditions-service-actions';
import {ContractCreateClickPaymentModeAction} from '@client/actions/contract-create-actions';
import {ParksSelectClickParkAction} from '@client/actions/parks-select-actions';
import {PaymentSelectMethodSelectMethodAction} from '@client/actions/payment-select-method-actions';
import {ServicesSelectClickServiceAction} from '@client/actions/services-select-actions';
import {SessionInitAction} from '@client/actions/session-actions';
import {ContractService} from '@client/core/services/contract.service';
import {Action} from '@client/lib/action';
import {
  getContractCancelSelectedServiceId,
  getContractDataSubscriptionService,
  getContractDataSubscriptionSpaqContractNo,
  getLayoutScopeId,
  getParksById,
  getSettingsIsActive,
  getSubscriptionData
} from '@client/selectors';
import {getContractDataService, getContractDataSubscriptionServiceId} from '@client/selectors/index';
import {ContractForm} from '@client/utils/shared-constants';

import {Actions, Effect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {EMPTY, forkJoin, iif, Observable, of, zip} from 'rxjs';
import {catchError, map, mapTo, mergeMap, switchMap, take, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {environment} from '../../configs/environment';
import {ViewportScroller} from "@angular/common";

@Injectable()
export class ContractEffects {
  @Effect({dispatch: true})
  acceptConditionsPayment$ = this.actions$.pipe(
    ofType(CONTRACT.ACCEPT_CONDITIONS_PAYMENT),
    switchMap(() =>
      this.contract.acceptConditionsPayment().pipe(
        map(() => new ContractAcceptConditionsPaymentSuccessAction()),
        catchError(error => of(new ContractAcceptConditionsPaymentFailureAction(error)))
      )
    )
  );
  @Effect({dispatch: true})
  acceptConditionsService$ = this.actions$.pipe(
    ofType(CONTRACT.ACCEPT_CONDITIONS_SERVICE),
    switchMap(() =>
      this.contract.acceptConditionsService().pipe(
        map(() => new ContractAcceptConditionsServiceSuccessAction()),
        catchError(error => of(new ContractAcceptConditionsServiceFailureAction(error)))
      )
    )
  );
  @Effect({dispatch: true})
  addPark$ = this.actions$.pipe(
    ofType(CONTRACT.ADD_PARK),
    map((action: ContractAddParkAction) => action.payload),
    switchMap(park =>
      this.contract.addPark(park).pipe(
        map(() => new ContractAddParkSuccessAction()),
        tap(() => {
          window.setTimeout(() => {
            this.viewportScroller.scrollToAnchor('step_2');
          }, 400);
        }),
        catchError(error => of(new ContractAddParkFailureAction(error)))
      )
    )
  );
  @Effect({dispatch: true})
  addPayment$ = this.actions$.pipe(
    ofType(CONTRACT.ADD_PAYMENT),
    map((action: ContractAddPaymentAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getContractDataService))),
    switchMap(([payment, service]: [any, any]) => {
      const test = this.fns.httpsCallable('paymentCreate');
      return test({payment, service, step: 2, lastUpdated: new Date().toISOString()}).pipe(
        map(() => new ContractAddPaymentSuccessAction()),
        catchError(e => {
          return of(new ContractAddPaymentFailureAction(e.message || e));
        })
      );
    })
  );
  @Effect({dispatch: true})
  addService$ = this.actions$.pipe(
    ofType(CONTRACT.ADD_SERVICE),
    map((action: ContractAddServiceAction) => action.payload),
    switchMap(service =>
      this.contract.addService(service).pipe(
        map(() => new ContractAddServiceSuccessAction(service)),
        tap(() => {
          window.setTimeout(() => {
            this.viewportScroller.scrollToAnchor('end');
          }, 400);
        }),
        catchError(error => {
          return of(new ContractAddServiceFailureAction(JSON.stringify(error)))
        })
      )
    )
  );
  @Effect({dispatch: true})
  addSubscriptionService$: Observable<Action> = this.actions$.pipe(
    ofType(CONTRACT.ADD_SUBSCRIPTION_SERVICE),
    map((action: ContractAddSubscriptionServiceAction) => action.payload),
    switchMap(x => {
      const test = this.fns.httpsCallable('serviceCreate');
      return test(x).pipe(
        map(x => new ContractAddSubscriptionServiceSuccessAction(x)),
        catchError(e => {
          return of(new ContractAddSubscriptionServiceFailureAction(e.message || e));
        })
      );
    })
  );
  @Effect({dispatch: true})
  cancelContract$: Observable<Action> = this.actions$.pipe(
    ofType(CONTRACT.CANCEL),
    map((action: ContractCancelAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getContractCancelSelectedServiceId)), this.store.pipe(select(getContractDataSubscriptionSpaqContractNo))),
    switchMap(([efffectiveDate, serviceId, contractNo]) => {
      const test = this.fns.httpsCallable('cancelContract');
      return test({effectiveDate: efffectiveDate, id: serviceId, contractNo}).pipe(
        map(x => new ContractCancelSuccessAction(efffectiveDate)),
        catchError(e => {
          return of(new ContractCancelFailureAction(e.message || e));
        })
      );
    })
  );
  @Effect({dispatch: true})
  cancelContractService$: Observable<Action> = this.actions$.pipe(
    ofType(CONTRACT.DELETE_SERVICE),
    map((action: ContractDeleteServiceAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getContractDataSubscriptionServiceId)), this.store.pipe(select(getContractDataSubscriptionSpaqContractNo))),
    switchMap(([action, id, contractNo]) => {
      const test = this.fns.httpsCallable('cancelContractService');
      return test({id, contractNo}).pipe(
        switchMap(() => this.contract.cancelService().pipe(
          map(x => new ContractDeleteServiceSuccessAction(action)),
          catchError(e => {
            return of(new ContractDeleteServiceFailureAction(e.message || e));
          })
        )),
        map(x => new ContractDeleteServiceSuccessAction(action)),
        catchError(e => {
          return of(new ContractDeleteServiceFailureAction(e.message || e));
        })
      )
    })
  );
  @Effect({dispatch: true})
  clickAcceptConditionPayment = this.actions$.pipe(ofType(CONTRACT_CONDITIONS_PAYMENT.CLICK_ACCEPT), mapTo(new ContractAcceptConditionsPaymentAction()));
  @Effect({dispatch: true})
  clickAcceptConditionsService = this.actions$.pipe(ofType(CONTRACT_CONDITIONS_SERVICE.CLICK_ACCEPT), mapTo(new ContractAcceptConditionsServiceAction()));
  @Effect({dispatch: true})
  clickAddService$ = this.actions$.pipe(
    ofType<ServicesSelectClickServiceAction>(SERVICES_SELECT.CLICK_SERVICE),
    withLatestFrom(this.store.pipe(select(getSettingsIsActive))),
    switchMap(([action, isActive]) => (!isActive ? EMPTY : this.store.pipe(select(getParksById, action.payload.service.parkIdentificationNo), take(1)))),
    map(park => new ContractAddParkAction(park))
  );
  @Effect({dispatch: true})
  clickCancel$ = this.actions$.pipe(
    ofType(CONTRACT_CANCEL.CLICK_CANCEL),
    map((action: ContractCancelClickCancelAction) => action.payload),
    map(s => new ContractCancelAction(s))
  );
  @Effect({dispatch: true})
  clickFinish = this.actions$.pipe(
    ofType(CONTRACT_CREATE.CLICK_FINISH),
    withLatestFrom(this.store.pipe(select(getContractDataSubscriptionService))),
    switchMap(([action, data]) => of(new ContractAddSubscriptionServiceAction(data)))
  );
  @Effect({dispatch: true})
  deleteContract$ = this.actions$.pipe(
    ofType(CONTRACT.DELETE),
    withLatestFrom(this.store.pipe(select(getSubscriptionData))),
    switchMap(([a, s]) => {
      const test = this.fns.httpsCallable('subscriptionCreate');
      return zip(this.contract.delete(), test({
        ...s,
        contractNo: 0,
        spaq: {...s.spaq, sex: 'Unknown', dynamicFieldJsonData: null, paymentMethod: null},
        custom: null
      })).pipe(
        map(() => new ContractDeleteSuccessAction()),
        catchError(error => of(new ContractDeleteFailureAction(error)))
      );
    })
  );
  @Effect({dispatch: true})
  findConditionsPayment = this.actions$.pipe(
    ofType(CONTRACT_CONDITIONS_PAYMENT.INIT, CONTRACT.HYDRATE_SUCCESS),
    switchMap(() =>
      this.contract.findConditionsPayment().pipe(
        map(text => new ContractConditionsPaymentHydrateSuccessAction(text)),
        catchError(error => of(new ContractConditionsPaymentHydrateFailureAction(error)))
      )
    )
  );
  @Effect({dispatch: true})
  findConditionsService = this.actions$.pipe(
    ofType(CONTRACT_CONDITIONS_SERVICE.INIT, CONTRACT.HYDRATE_SUCCESS),
    switchMap(() =>
      this.contract.findConditionsService().pipe(
        map(text => new ContractConditionsServiceHydrateSuccessAction(text)),
        catchError(error => of(new ContractConditionsServiceHydrateFailureAction(error)))
      )
    )
  );
  @Effect({dispatch: true})
  hydrateContract$ = this.actions$.pipe(
    ofType<ContractHydrateAction>(CONTRACT.HYDRATE),
    switchMap(action =>
      this.contract.hydrate().pipe(
        takeUntil(this.actions$.pipe(ofType(SESSION.DESTROY))),
        map(contract => new ContractHydrateSuccessAction(contract, action.correlationId)),
        catchError(error => of(new ContractHydrateFailureAction(error, action.correlationId)))
      )
    )
  );
  @Effect({dispatch: false})
  scrollTop$ = this.actions$.pipe(
    ofType(
      CONTRACT.ADD_PARK_SUCCESS,
      CONTRACT.ADD_PAYMENT_SUCCESS,
      CONTRACT.ADD_SERVICE_SUCCESS,
      CONTRACT.ACCEPT_CONDITIONS_SERVICE_SUCCESS,
      CONTRACT.ACCEPT_CONDITIONS_PAYMENT_SUCCESS,
      SUBSCRIPTION_EDIT.UPDATE_SUCCESS,
      VEHICLES_ADD.ADD_SUCCESS,
      VEHICLES_ADD.UPDATE_SUCCESS
    ),
    tap(() => {
      const querySelected = document.querySelector('spaq-layout-account-content');
      if (querySelected) {
        document.querySelector('spaq-layout-account-content').scrollTop = 0;
      }
    })
  );
  @Effect({dispatch: true})
  searchContract$: Observable<Action> = this.actions$.pipe(
    ofType(CONTRACT.SEARCH),
    map((action: ContractSearchAction) => action.payload),
    switchMap((x: ContractForm) => {
      const test = this.fns.httpsCallable('subscriptionSearch');
      return test(x).pipe(
        map(x => new ContractSearchSuccessAction(x)),
        catchError(e => of(new ContractSearchFailureAction(e.message || e)))
      );
    })
  );
  @Effect({dispatch: true})
  selectMethod$ = this.actions$.pipe(
    ofType(PAYMENT_SELECT_METHOD.SELECT_METHOD),
    map((action: PaymentSelectMethodSelectMethodAction) => action.payload),
    map(s => new ContractAddPaymentAction(s))
  );
  @Effect({dispatch: true})
  selectPark$ = this.actions$.pipe(
    ofType(PARKS_SELECT.CLICK_PARK),
    map((action: ParksSelectClickParkAction) => action.payload),
    map(p => new ContractAddParkAction(p))
  );
  @Effect({dispatch: false})
  selectPaymentMode = this.actions$.pipe(
    ofType<ContractCreateClickPaymentModeAction>(CONTRACT_CREATE.CLICK_PAYMENT_MODE),
    switchMap(action => this.contract.setPaymentMode(action.payload))
  );
  @Effect({dispatch: true})
  selectservice$ = this.actions$.pipe(
    ofType(SERVICES_SELECT.CLICK_SERVICE),
    withLatestFrom(this.store.pipe(select(getLayoutScopeId))),
    map(([action, scopeId]: any) => new ContractAddServiceAction({...(action.payload || {}), scopeId}))
  );
  @Effect({dispatch: true})
  sessionInit$ = this.actions$.pipe(
    ofType<SessionInitAction>(SESSION.INIT),
    mergeMap(action => iif(() => environment.services.contract, of(new ContractHydrateAction(null, action.correlationId)), EMPTY))
  );

  constructor(private actions$: Actions, private store: Store<any>, private contract: ContractService, private fns: AngularFireFunctions, private viewportScroller: ViewportScroller) {
  }
}
