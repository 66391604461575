import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {NgModule, ErrorHandler, isDevMode} from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CoreGuardModule } from '@client/core/core-guard.module';
import { CoreLayoutModule } from '@client/core/core-layout.module';
import { CoreServicesModule } from '@client/core/core-services.module';
import { PipesModule } from '@client/shared/pipes.module';
import { SharedComponentsModule } from '@client/shared/shared-components.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PlanctonModule } from '@plancton/plancton';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { IntlModule } from '@progress/kendo-angular-intl';
import { NgxMdModule } from 'ngx-md';
import { AppComponent } from './app.component';
import { environment } from './configs/environment';
import * as Sentry from '@sentry/angular';
import { EffectsModule } from './effects.module';
import { StoreModule } from './store.module';
import { StoresModule } from './stores.module';
import { TelemetryService } from './telemetry';
import { WebModule } from './web/web.module';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

export class FirebaseTransLoader implements TranslateLoader {
  constructor(private db: AngularFireDatabase) {}

  public getTranslation(lang: string, prefix: string = 'language/'): any {
    return this.db.object(`${prefix}${lang}`).valueChanges();
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/');
}

export function FbTransLoaderFactory(db: AngularFireDatabase) {
  return new FirebaseTransLoader(db);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    CoreServicesModule,
    CoreLayoutModule,
    SharedComponentsModule,
    BrowserModule,
    CoreGuardModule,
    PipesModule,
    EffectsModule,
    StoreModule,
    DialogModule,
    IntlModule,
    StoresModule,
    PlanctonModule.forRoot({
      firebase: {
        config: environment.firebase
      },
      locale: 'fr-CA'
    }),
    WebModule,
    NgxMdModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: FbTransLoaderFactory,
        deps: [AngularFireDatabase]
      }
    }),
    NgxGoogleAnalyticsModule.forRoot('G-J19C6V2P5T'),
    NgxGoogleAnalyticsRouterModule,
    RouterModule
  ],
  providers: [TelemetryService, {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false,
    })
  }],
  bootstrap: [AppComponent]
})
export class AppModule {}
