import {environment} from '@client/configs/environment';

export const getConfigByScope = (configKey: string) => {
  try {
    let scope = localStorage.getItem('selectedOrganization')
    if (!scope) {
      scope = document.location.hostname.split('.')[0];
    }
    scope = scope.toLowerCase();
    return environment.scopes[scope] && environment.scopes[scope][configKey] || null;
  } catch (e) {
    console.error(e);
    return false;
  }
};
