import { State as AdminNotesFormState } from "@client/reducers/admin-notes-form-reducer";
import { Selector } from "@ngrx/store";
import { ServiceMetaInfo } from "@client/utils/shared-constants";
export const getIsActive: Selector<AdminNotesFormState, boolean> = (state: AdminNotesFormState) => state.isActive;
export const getMessage: Selector<AdminNotesFormState, string | null> = (state: AdminNotesFormState) => state.message;
export const getHasError: Selector<AdminNotesFormState, boolean> = (state: AdminNotesFormState) => state.hasError;
export const getHasSuccess: Selector<AdminNotesFormState, boolean> = (state: AdminNotesFormState) => state.hasSuccess;
export const getIsLoading: Selector<AdminNotesFormState, boolean> = (state: AdminNotesFormState) => state.isLoading;
export const getSelectedServices: Selector<AdminNotesFormState, []> = (state: AdminNotesFormState) => state.selectedServices;
export const getForm: Selector<AdminNotesFormState, ServiceMetaInfo | null> = (state: AdminNotesFormState) => state.form;
export const getFormNote: Selector<AdminNotesFormState, string> = (state: AdminNotesFormState) => getForm(state) && getForm(state).note;
