import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireFunctions } from '@angular/fire/functions';
import { getOobContinueUrl } from '@client/selectors';
import {
  AuthCodeInfo,
  CodeForm,
  EmailEditForm,
  LoginForm,
  RegisterEmailForm,
  ResetPasswordForm,
  SubscriptionInfo,
  UpdatePasswordForm,
  User
} from '@client/utils/shared-constants';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import firebase from 'firebase';
import FirebaseUser = firebase.User;

import {EMPTY, forkJoin, from, Observable, of, throwError, timer, zip} from 'rxjs';
import { catchError, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../configs/environment';
import RecaptchaVerifier = firebase.auth.RecaptchaVerifier;
import Auth = firebase.auth.Auth;

@Injectable()
export class AuthService {
  _user: FirebaseUser;

  get user() {
    return this._user;
  }

  get authState() {
    return this.afAuth.authState;
  }

  get uid() {
    return this._user.uid ? this._user.uid : null;
  }

  captchaId: number;
  confirmationResult: any;
  recaptchaVerifier: RecaptchaVerifier;

  constructor(
    public afAuth: AngularFireAuth,
    private store: Store<any>,
    private http: HttpClient,
    private dialogService: DialogService,
    private fns: AngularFireFunctions,
    private translate: TranslateService,
    public afDb: AngularFireDatabase
  ) {
    this.afAuth.authState.subscribe();
  }

  checkCode(code: string) {
    const refreshUser = this.fns.httpsCallable('refreshUser');
    return forkJoin(
      this.store.select(getOobContinueUrl).pipe(
        take(1),
        switchMap(x => {
          const res = /uid=(.*)$/g.exec(x);

          return res ? refreshUser(res[1]) : of(null);
        })
      ),
      this.afAuth.checkActionCode(code)
    ).pipe(catchError(e => throwError(this.translate.instant((e && (e.code || e.message)) || 'unknown'))));
  }

  checkPhone(phoneNumber) {
    const url = environment.backendApiUrl + '/spaq';
    return Observable.create(observer => {
      this.http.get(`${url}/check_phone/+1${phoneNumber}`).subscribe(
        e => {
          observer.next(true);
          observer.complete();
        },
        e => {
          observer.error(this.translate.instant(e.code || 'unknown'));
        }
      );
    });
  }

  clearCaptcha() {
    this.confirmationResult = null;
    if (this.recaptchaVerifier) {
      this.recaptchaVerifier.clear();
    }
  }

  confirmPasswordReset(data: { code: string; newPassword: string }) {
    return from(this.afAuth.confirmPasswordReset(data.code, data.newPassword)).pipe(catchError(e => throwError(this.translate.instant(e.code || 'unknown'))));
  }

  createUserWithEmailAndPasswordAction(form: RegisterEmailForm): Observable<any> {
    const test = this.fns.httpsCallable('createUser');
    return test({ ...form, host: window.location.hostname + ':' + window.location.port }).pipe(
      catchError(e => {
        const res = e && e.message;
        return throwError(this.translate.instant(res || 'unknown'));
      })
    );
  }

  enableSessionExpire() {
    timer(1000 * 60 * 5).subscribe(_ => {
      this.showConfirmation();
    });
  }

  forgotPassword(model) {
    return Observable.create(observer => {
      try {
        // this.afAuth.languageCode = of('fr').toPromise();
        this.afAuth
          .sendPasswordResetEmail(model.email, {
            url: window.location.protocol + '//' + window.location.hostname + '/auth/login/email'
          })
          .then(e => {
            observer.next(true);
            observer.complete();
          })
          .catch(e => {
            observer.error(this.translate.instant((e.code || '').replace('/', '.') || 'unknown'));
          });
      } catch (e) {
        observer.error(this.translate.instant((e.code || '').replace('/', '.') || 'unknown'));
      }
    });
  }

  generateCaptcha() {
    this.recaptchaVerifier = new RecaptchaVerifier('captcha', {
      size: 'invisible',
      callback: result => this.onCaptchaSolve,
      'expired-callback': _ => this.onCaptchaExpire
    });
    const test = this.recaptchaVerifier.render();

    return Observable.create(observer => {
      test
        .then(widgetId => {
          this.captchaId = widgetId;
          observer.next(widgetId);
          observer.complete();
        })
        .catch(e => {
          observer.error(this.translate.instant(e.code || 'unknown'));
        });
    });
  }

  getCode({ companyCode, userCode }: CodeForm) {
    return Observable.create(observer => {
      const url = `https://us-central1-spaq-156518.cloudfunctions.net/getCode?companyCode=${companyCode}&userCode=${userCode}`;
      return this.http.get(url).subscribe(
        data => {
          if (data === null) {
            observer.error({ code: 'not-found', message: 'Code introuvable' });
          } else {
            observer.next(data);
            observer.complete();
          }
        },
        e => {
          observer.error(this.translate.instant(e.code || 'unknown'));
        }
      );
    });
  }

  initCaptcha() {
    this.captchaId = null;
    this.recaptchaVerifier = new RecaptchaVerifier('captcha', {
      size: 'invisible',
      callback: result => this.onCaptchaSolve,
      'expired-callback': _ => this.onCaptchaExpire
    });
    this.recaptchaVerifier
      .render()
      .then(widgetId => {
        this.captchaId = widgetId;
      })
      .catch(e => {});
  }

  loggedIn() {
    return !!this._user;
  }

  login({ email, password }: LoginForm) {
    return from(
      this.afAuth.setPersistence(Auth.Persistence.SESSION).then(() => {
        // this.afAuth.languageCode = of('fr').toPromise();
        return this.afAuth.signInWithEmailAndPassword(email.trim(), password.trim()).then(r => {
          return {
            displayName: r.user.displayName,
            phoneNumber: r.user.phoneNumber,
            email: r.user.email,
            photoURL: r.user.photoURL,
            emailVerified: r.user.emailVerified,
            uid: r.user.uid
          } as User;
        }).then(x => {
          const scope = document.location.hostname.split('.')[0].toUpperCase();

          const domain = document.location.hostname
            .split('.')
            .splice(1)
            .join('.');

          return this.afDb.object('/logs/' + x.uid + '/login/' + new Date().getTime()).set({
            createdAt: new Date().toISOString(),
            userAgent: navigator.userAgent || '',
            webdriver: navigator.webdriver || false,
            vendor: navigator.vendor || '',
            language: navigator.language || '',
            connection: navigator['connection'] || null,
            scope,
            domain
          }).then(() => x);
        });
      })
    ).pipe(catchError(e => {
      return throwError(this.translate.instant((e.code || '').replace('/', '.') || 'unknown'))
    }));
  }

  logout() {
    this.afDb.database.goOffline();
    return from(this.afAuth.signOut()).pipe(
      tap(() => {
        this.afDb.database.goOnline();
      }),
      catchError(e => throwError(this.translate.instant(e.code || 'unknown')))
    );
  }

  onCaptchaExpire() {
    this.clearCaptcha();
  }

  onCaptchaSolve(result) {}

  register(form: RegisterEmailForm) {
    const test = this.fns.httpsCallable('createUser');
    return test({ ...form, host: window.location.hostname + ':' + window.location.port}).pipe(
      catchError(e => {
        return throwError(e.message);
      })
    );
  }

  reload() {

    return this.afAuth.user.pipe(
      switchMap((u: FirebaseUser) => u ? from(u.reload()).pipe(
        mapTo(u.toJSON()),
        catchError(e => throwError(this.translate.instant(e.code || 'unknown')))
      ) : EMPTY)
    );
  }

  renderCaptcha() {
    const self = this;
    this.recaptchaVerifier = new RecaptchaVerifier('captcha', {
      size: 'invisible',
      callback: result => {},
      'expired-callback': () => {}
    });

    this.recaptchaVerifier
      .render()
      .then(widgetId => {
        this.captchaId = widgetId;
      })
      .catch(e => throwError(this.translate.instant(e.code || 'unknown')));
  }

  resetPassword({ password, code }: ResetPasswordForm) {
    return from(
      this.afAuth
        .confirmPasswordReset(code, password)
        .then(e => {
          return { code, password };
        })
        .catch(e => throwError(this.translate.instant(e.code || 'unknown')))
    );
  }

  sendEmailVerificationLink() {
    // this.afAuth.languageCode = of('fr').toPromise();

    return this.afAuth.user.pipe(
      take(1),
      switchMap((u: FirebaseUser) =>  from(
        u.sendEmailVerification({
          url: window.location.protocol + '//' + window.location.hostname + '/?uid=' + u.uid
        })
      ).pipe(catchError(e => throwError(this.translate.instant(e.code || 'unknown')))))
    );
  }

  public showConfirmation() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Session expirée',
      content: 'Votre session est expirée. Souhaitez-vous rester en ligne?',
      actions: [{ text: 'Non' }, { text: 'Oui', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });
    const signOut = timer(1000 * 60).subscribe(_ => {
      dialog.close();
    });
    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        return this.logout();
      } else {
        if (result.text === 'Oui') {
          signOut.unsubscribe();
          this.enableSessionExpire();
        } else {
          return this.logout();
        }
      }
    });
  }

  solveCaptcha(code: string) {
    return from(
      this.confirmationResult.confirm(code).then(result => {
        const user = {
          displayName: result.user.displayName,
          phoneNumber: result.user.phoneNumber,
          email: result.user.email,
          photoURL: result.user.photoURL,
          emailVerified: result.user.emailVerified,
          uid: result.user.uid
        } as User;
        this.clearCaptcha();
        return user;
      })
    ).pipe(catchError(e => throwError(this.translate.instant(e.code || 'unknown'))));
  }

  updateEmail(form: EmailEditForm) {
    return this.afAuth.user.pipe(
      switchMap((u: FirebaseUser) => this.login({ email: u.email, password: form.current_password }).pipe(
        switchMap(() =>
          from(
            u.updateEmail(form.email).then(() =>
              u.sendEmailVerification({
                url: window.location.protocol + '//' + window.location.hostname + '/updateEmail?uid=' + u.uid + '&d=' + new Date().getTime()
              })
            )
          ).pipe(catchError(e => throwError(this.translate.instant(e.code || 'unknown'))))
        )
      ))
    );
  }

  updatePassword(data: UpdatePasswordForm): Observable<any> {
    return this.afAuth.user.pipe(
      switchMap((u: FirebaseUser) => zip(
        this.login({ email: u.email, password: data.current_password }),
        u.updatePassword(data.password).catch(e => throwError(this.translate.instant(e.code || 'unknown')))
      ))
    );
  }

  validateCode(code) {
    return Observable.create(observer => {
      this.afAuth
        .checkActionCode(code)
        .then(e => {
          observer.next(true);
          observer.complete();
        })
        .catch(e => throwError(this.translate.instant(e.code || 'unknown')));
    });
  }

  validateEmailCode(data: AuthCodeInfo) {
    const refreshUser = this.fns.httpsCallable('refreshUser');
    return zip(
      this.store.select(getOobContinueUrl).pipe(
        switchMap(x => {
          const res = /uid=(.*)$/g.exec(x);

          if (res) {
            return res ? refreshUser(res[1]) : of(null);
          }
        })
      ),
      this.afAuth.applyActionCode(data.oobCode)
    ).pipe(catchError(e => throwError(this.translate.instant((e && (e.code || e.message)) || 'unknown'))));
  }
}
