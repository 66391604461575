import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { REGISTER_SUBSCRIPTION, SUBSCRIPTION_EDIT } from '@client/actions';
import { AuthLogoutAction } from '@client/actions/auth-actions';
import { RegisterSubscriptionCreateAction, RegisterSubscriptionCreateFailureAction, RegisterSubscriptionCreateSuccessAction } from '@client/actions/register-subscription-actions';
import { SubscriptionEditClickSubmitAction, SubscriptionEditUpdateAction } from '@client/actions/subscription-edit-actions';
import { AuthService } from '@client/core/services/auth.service';
import { Action } from '@client/lib/action';
import { getRegisterSubscriptionIsActive, getSubscriptionEditForm } from '@client/selectors';
import { getSubscriptionDataSpaqContractNo } from '@client/selectors/index';
import { SubscriptionInfo } from '@client/utils/shared-constants';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { empty, Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class RegisterESubscriptionEffects {
  @Effect({ dispatch: true })
  createSubscription$: Observable<Action> = this.actions$.pipe(
    ofType(REGISTER_SUBSCRIPTION.CREATE),
    map((action: RegisterSubscriptionCreateAction) => action.payload),
    switchMap((x: SubscriptionInfo) => {
      const test = this.fns.httpsCallable('subscriptionCreate');
      return test({ ...x, spaq: { ...x.spaq, sex: 'Unknown' }, custom: null, paymentMethod: null, dynamicFieldJsonData: null }).pipe(
        map(x => new RegisterSubscriptionCreateSuccessAction(x)),
        catchError(e => of(new RegisterSubscriptionCreateFailureAction(e)))
      );
    })
  );
  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(ofType(REGISTER_SUBSCRIPTION.CLICK_LOGOUT), mapTo(new AuthLogoutAction()));
  @Effect({ dispatch: true })
  submit$: Observable<any> = this.actions$.pipe(
    ofType<SubscriptionEditClickSubmitAction>(SUBSCRIPTION_EDIT.CLICK_SUBMIT),
    withLatestFrom(this.store.select(getSubscriptionEditForm), this.store.select(getRegisterSubscriptionIsActive), this.store.select(getSubscriptionDataSpaqContractNo)),
    switchMap<any, any>(([a, b, isActive, contractNo]) => (isActive ? (!contractNo ? of(new RegisterSubscriptionCreateAction(b)) : of(new SubscriptionEditUpdateAction(b))) : empty()))
  );

  constructor(private actions$: Actions, private store: Store<any>, private authService: AuthService, private fns: AngularFireFunctions) {}
}
