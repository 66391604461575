import {
  getAccessCodeData,
  getContractCreateSelectedParkId,
  getContractDataService,
  getLayoutIsScope,
  getLayoutScopeId,
  getOrganizationEntities,
  getParksById,
  getParksEntities,
  getParksIds,
  getParksSelectState,
  getSectorsCollectionById,
  getServicesAll,
  getServicesById,
  getServicesEntities
} from '@client/selectors';
import { getSessionClaimsAdmin } from '@client/selectors/index';
import { AccessCodeGroupInfo, ServiceInfo } from '@client/utils/shared-constants';
import { createSelector } from '@ngrx/store';

import { chain, uniqBy } from 'lodash';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';

import { environment } from '../configs/environment';

export const getContractServices = createSelector(
  getServicesAll,
  getAccessCodeData,
  getLayoutIsScope,
  getLayoutScopeId,
  state => state,
  (a: ServiceInfo[], b: AccessCodeGroupInfo, isScope, scopeId, state) => {
    const publicGroup = {};

    // Les services public dans un groupe privé doivent être acceptés
    if (scopeId) {
      Object.values((state.__organization.entities[scopeId] && state.__organization.entities[scopeId].groups) || {}).forEach(g => {
        if (g.public && g.services) {
          Object.values(g.services || {}).forEach(s => {
            publicGroup[s.serviceId] = true;
          });
        }
      });
    }

    return !a
      ? []
      : a.filter(x => {
          if (x.serviceType === 'Public' || x.serviceType === 'PublicWithApprobation') {
            return true;
          }
          if ((b && b.services && b.services[x.availableServiceId]) || publicGroup[x.availableServiceId]) {
            return true;
          }
          return false;
        });
  }
);

export const getCustomFields = createSelector(
  getAccessCodeData,
  state => state,
  (x, state) => {
    let c = [];
    if (x && x.meta && x.meta.customFields) {
      c = values(x.meta.customFields);
    }
    c.sort((a, b) => {
      return Number(a.order || 0) - Number(b.order || 0);
    });
    return c.map(r => {
      if (r && r.values) {
        return { ...r, values: keyBy(r.values, 'key') };
      }
      return r;
    });
  }
);

export const getCustomPickupLocations = createSelector(
  getAccessCodeData,
  state => state,
  (x: any, state) => {
    let c = [];

    if (x && x.meta && x.meta.customFields && x.meta.customFields.lieu_de_recuperation && x.meta.customFields.lieu_de_recuperation && x.meta.customFields.lieu_de_recuperation.values) {
      return x.meta.customFields.lieu_de_recuperation.values.reduce((a, b) => {
        a[b.key] = b;
        return a;
      }, {});
    }
    return {};
  }
);
export const getContractFilteredServices = createSelector(
  getServicesAll,
  getContractServices,
  getLayoutIsScope,
  getLayoutScopeId,
  getContractCreateSelectedParkId,
  state => state,
  (a: ServiceInfo[], s: ServiceInfo[], isScope, scopeId, selectedParkId, state) => {

    if (selectedParkId === 0) {
      return a;
      /*
      return a.filter(a => {
        if(a.serviceType === 'Public' || a.serviceType === 'PublicWithApprobation') {
          // On ne retourne pas les services public du groupe si le scope est activé
          return isScope ? true : false;
        }
        return true;
      });
       */
    }

    const list = !s ? [] : s.filter(x => x.parkIdentificationNo === selectedParkId);

    // On ne retourne pas les services public du groupe si le scope est activé
    const publicServices = list
      .filter(x => x.serviceType === 'Public' || x.serviceType === 'PublicWithApprobation')
      .sort((a: ServiceInfo, b: ServiceInfo) => {
        return !b ? 0 : a.serviceDescription && a.serviceDescription.localeCompare(b.serviceDescription || '');
      });
    /*
        const publicServices = list.filter(x => isScope && (x.serviceType === 'Public' || x.serviceType === 'PublicWithApprobation')).sort((
      a: ServiceInfo, b: ServiceInfo) => {
      return !b ? 0 : a.serviceDescription && a.serviceDescription.localeCompare(b.serviceDescription || '');
    });
     */
    const privateServices = list
      .filter(x => x.serviceType === 'Private' || x.serviceType === 'PrivateWithApprobation')
      .sort((a: ServiceInfo, b: ServiceInfo) => {
        return !b ? 0 : a.serviceDescription && a.serviceDescription.localeCompare(b.serviceDescription || '');
      });

    return [...privateServices, ...publicServices];
  }
);
export const getContractPaymentMethods = createSelector(
  getContractDataService,
  state => state,
  (a: ServiceInfo | null, state) => {
    const methods = [];

    if (!a) {
      return methods;
    }
    if (a.isBankingCardPaymentAccepted) {
      methods.push('Credit');
    }
    if (a.isDasPaymentAccepted) {
      methods.push('DAS');
    }
    if (a.isFinancialInstitutionPaymentAccepted) {
      methods.push('Cash');
    }
    if (a.isPpaPaymentAccepted) {
      methods.push('PreAuthorized');
    }
    return methods;
  }
);
export const getContractParks = createSelector(
  getContractServices,
  state => state,
  (a: ServiceInfo[] | null, state) => {
    return uniqBy<ServiceInfo>(a, 'parkIdentificationNo')
      .map(x => getParksById(state, x.parkIdentificationNo))
      .filter(x => !!x)
      .sort((a, b) => {
        return a.parkName.localeCompare(b.parkName);
      });
  }
);
export const getEmptyParksIds = createSelector(
  getServicesAll,
  getParksIds,
  state => state,
  (services: ServiceInfo[], parksIds: string[], state) => {
    const parksNotEmpty = {};

    services.forEach(s => {
      parksNotEmpty[s.parkIdentificationNo] = null;
    });

    return parksIds.filter(id => {
      if (parksNotEmpty[id]) {
        return false;
      }
      return true;
    });
  }
);

export const getNotEmptyParksIds = createSelector(
  getServicesAll,
  state => state,
  (services: ServiceInfo[], state) => {
    return Array.from(new Set(services.map(s => s.parkIdentificationNo)));
  }
);
export const getOrganizationNameByScope = createSelector(
  getOrganizationEntities,
  getLayoutIsScope,
  getLayoutScopeId,
  state => state,
  (organizations, isScope, scopeId) => {
    if (!isScope) {
      return null;
    }
    if (!organizations || typeof organizations !== 'object' || typeof organizations[scopeId] !== 'object' || typeof organizations[scopeId].organizationName !== 'string') {
      return null;
    }
    return organizations[scopeId].organizationName;
  }
);
export const getOrganizationService = createSelector(
  getServicesEntities,
  getLayoutIsScope,
  getLayoutScopeId,
  getOrganizationEntities,
  state => state,
  (services, isScope, scopeId, organizations, state) => {
    if (!isScope) {
      return services;
    }
    if (!organizations || typeof organizations !== 'object' || typeof organizations[scopeId] !== 'object' || typeof organizations[scopeId].groups !== 'object') {
      return [];
    }

    const newServicesEntities = {};

    if (organizations[scopeId].groups) {
      Object.keys(organizations[scopeId].groups)
        .map(k => organizations[scopeId].groups[k])
        .map(x => {
          if (x && typeof x === 'object' && x.services) {
            Object.keys(x.services)
              .filter(i => !!services[i])
              .forEach(k => {
                newServicesEntities[k] = services[k];
              });
          }
        });
    }
    return newServicesEntities;
  }
);
export const getOrganizationGroupsByScopeId = createSelector(
  getOrganizationEntities,
  getLayoutScopeId,
  state => state,
  (organizations,  scopeId) => {
    if (!organizations || typeof organizations !== 'object' || typeof organizations[scopeId] !== 'object' || typeof organizations[scopeId].groups !== 'object') {
      return null;
    }
    return Object.values(organizations[scopeId].groups);
  }
);

export const getOrganizationServicesByGroupId = createSelector(
  getOrganizationEntities,
  getServicesEntities,
  getLayoutScopeId,
  state => state,
  (organizations,  services, scopeId, state, selectedGroupId) => {
    if (!organizations || typeof organizations !== 'object' || typeof organizations[scopeId] !== 'object' || typeof organizations[scopeId].groups !== 'object' || !organizations[scopeId].groups[selectedGroupId]) {
      return null;
    }
    return Object.keys(organizations[scopeId].groups[selectedGroupId].services || {}).filter(serviceId => services[serviceId]).map(serviceId => services[serviceId]);
  }
);
export const getOrganizationSectorsByGroupId = createSelector(
  getOrganizationServicesByGroupId,
  state => state,
  (services, state) => {
    const allSectors = (state.__sectors && state.__sectors.collection || []).reduce((a, b) => {
      a[b.sectorId] = b;
      return a;
    }, {});
    const sectors = (services || []).map(service => {
      return allSectors[service.sectorId];
    });
    return chain(sectors)
      .uniqBy(x => x.id)
      .value();
  }
);
export const getFilteredParkList = createSelector(
  getParksSelectState,
  getParksEntities,
  getOrganizationService,
  getAccessCodeData,
  getSessionClaimsAdmin,
  getLayoutIsScope,
  getLayoutScopeId,
  state => state,
  (parkSelectState, parksEntities, servicesEntities, accessCode, isAdmin, isScope, scopeId, state) => {
    const values = Object.keys(servicesEntities).map(key => servicesEntities[key]);

    const publicGroup = {};

    let allowdParksForCurrentScope = {};

    if(isScope) {
      allowdParksForCurrentScope = (Object.values(state.__organization.entities[scopeId].groups) || []).reduce((a: any, b: any) => {
        (Object.values(b.services || {})).forEach((s: any) => {
          a[s.parkIdentificationNo] = true;
        });
        return a;
      }, {});
    }

    // Les services public dans un groupe privé doivent être acceptés
    if (scopeId) {
      Object.values((state.__organization.entities[scopeId] && state.__organization.entities[scopeId].groups) || {}).forEach(g => {
        if (g.public && g.services) {
          Object.values(g.services || {}).forEach(s => {
            publicGroup[s.serviceId] = true;
          });
        }
      });
    }
    console.log('AAAAAA', scopeId, isScope);
    const filteredParkList: any = Array.from(new Set(values)).reduce((acc, cur, i) => {
      acc[cur.parkIdentificationNo] = {
        // On autorise les services publics uniquement si le scope est activé
        public: cur.serviceType === 'Public' || (cur.serviceType === 'PublicWithApprobation') || publicGroup[cur.availableServiceId] ? true : (acc[cur.parkIdentificationNo] && acc[cur.parkIdentificationNo].public) || false,
        private:
          (cur.serviceType === 'Private' || cur.serviceType === 'PrivateWithApprobation') && !publicGroup[cur.availableServiceId] && ((accessCode && accessCode.services && accessCode.services[cur.availableServiceId]) || isScope)
            ? true
            : (acc[cur.parkIdentificationNo] && acc[cur.parkIdentificationNo].private) || false,
        id: cur.parkIdentificationNo
      };
      return acc;
    }, {});

console.log('AAAAAAAAAAAAAAAAAAAAAAAA', filteredParkList);

    Object.keys(parksEntities).forEach((v, i) => {
      if (!filteredParkList[v]) {
        filteredParkList[v] = { empty: true, id: v };
      }
    });

    const pValues = Object.keys(filteredParkList).map(key => filteredParkList[key]);
    const parks = pValues
      .filter((x: any) => {
        if(isScope && !allowdParksForCurrentScope[x.id]) {
          return false;
        }
        if(!isScope) {
          if([380, 354, 380, 4, 9].includes(x.id)) {
            return true;
          }
          return false;
        }
        if (parkSelectState.showEmpty && x.empty) {
          return true;
        }
        if ((parkSelectState.showPrivate || isScope) && x.private === true) {
          return true;
        }
        if (parkSelectState.showPublic && x.public === true) {
          return true;
        }
        //  if (parkSelectState.showPublic && x.public === true && isScope) {
        //    return true;
        //  }
        return false;
      })
      .map((i: any) => parksEntities[i.id]);

    if (isAdmin && environment.showInternal) {
      parks.push({
        city: 'Québec',
        civicNumber1: '0 rue des administrateurs',
        civicNumber2: '0 rue des administrateurs',
        gpsLatitude: 1,
        gpsLongitude: 1,
        parkIdentificationNo: 0,
        parkName: '_INTERNAL_',
        phoneNo: '4186814811',
        phoneExtension: '4186814811',
        postalCode: 'G1J3Z2',
        province: 'QC',
        serverHostName: 'regional.qc.spaq.com.wait',
        email: ''
      });
    }

    return parks.sort((a, b) => a.parkName.localeCompare(b.parkName));
  }
);
export const getServicesDistinctSectorId = createSelector(getServicesAll, (a: ServiceInfo[]) => {
  return chain(a)
    .uniqBy(x => x.sectorId)
    .value();
});
export const getServicesSectors = createSelector(
  getServicesDistinctSectorId,
  state => state,
  (a: ServiceInfo[], state) => {
    return !a ? [] : a.map(x => getSectorsCollectionById(state, x.sectorId)).filter(x => !!x);
  }
);

export const getServicesParks = createSelector(
  getServicesSectors,
  state => state,
  (a: any, state) => {
    const uniqParks = {};
    return !a ? [] : a.map(x => {
      if(uniqParks[x.parkIdentificationNo || 0]) {
        return null;
      }
      uniqParks[x.parkIdentificationNo || 0] = true;
      return getParksById(state, x.parkIdentificationNo || 0);
    }).filter(x => !!x);
  }
);
export const getPublicServices = createSelector(getServicesAll, (a: ServiceInfo[]) => {
  return a.filter(x => x.serviceType === 'Public' || x.serviceType === 'PublicWithApprobation');
});

export const getPrivateServices = createSelector(getServicesAll, (a: ServiceInfo[]) => {
  return a.filter(x => x.serviceType === 'Private' || x.serviceType === 'PrivateWithApprobation');
});

export const getPrivateServicesForSelectedPark = createSelector(
  getPrivateServices,
  getContractCreateSelectedParkId,
  state => state,
  (a: ServiceInfo[], selectedParkId, state) => {
    return a.filter(x => {
      const sector = getSectorsCollectionById(state, x.sectorId);
      return sector && sector.parkIdentificationNo === selectedParkId ? true : false;
    });
  }
);

export const getAllServicesForSelectedPark = createSelector(
  getServicesAll,
  getContractCreateSelectedParkId,
  state => state,
  (a: ServiceInfo[], selectedParkId, state) => {
    return a.filter(x => {
      const sector = getSectorsCollectionById(state, x.sectorId);
      return sector && sector.parkIdentificationNo === selectedParkId ? true : false;
    });
  }
);

export const getPrivateServicesByParkNumber = createSelector(
  getPrivateServices,
  state => state,
  (a: ServiceInfo[], state, selectedParkId) => {
    return a.filter(x => {
      const sector = getSectorsCollectionById(state, Number(x.sectorId));
      return sector && sector.parkIdentificationNo === selectedParkId ? true : false;
    });
  }
);
export const getAllServicesByParkNumber = createSelector(
  getServicesAll,
  state => state,
  (a: ServiceInfo[], state, selectedParkId) => {
    return a.filter(x => {
      const sector = getSectorsCollectionById(state, Number(x.sectorId));
      return sector && sector.parkIdentificationNo === selectedParkId ? true : false;
    });
  }
);
export const getPublicServicesForSelectedPark = createSelector(
  getPublicServices,
  getContractCreateSelectedParkId,
  state => state,
  (a: ServiceInfo[], selectedParkId, state) => {
    return a.filter(x => {
      const sector = getSectorsCollectionById(state, x.sectorId);
      return sector && sector.parkIdentificationNo === selectedParkId ? true : false;
    });
  }
);
export const getAccessCodeServices = createSelector(
  getAccessCodeData,
  getServicesAll,
  state => state,
  (a, b, state) => {
    if (!a) {
      return [];
    }
    const aServices = values(a.services || []);
    const services = aServices.map(x => getServicesById(state, x.serviceId)).filter(x => !!x);
    return services;
  }
);
