import {
  APP,
  EMAIL_EDIT,
  AUTH,
  SESSION,
  FAQ,
  CONTRACT,
  CONTRACT_CANCEL,
  CONTRACT_CREATE,
  CONTRACT_DETAILS,
  CONTRACT_CONDITIONS_SERVICE,
  CONTRACT_CONDITIONS_PAYMENT,
  PARKS_SELECT,
  CALENDAR,
  PROFILE,
  PROFILE_DETAILS,
  PROFILE_EDIT,
  SUBSCRIPTION,
  LOGIN,
  REGISTER_EMAIL,
  SUBSCRIPTION_DETAILS,
  SUBSCRIPTION_EDIT,
  REGISTER_SUBSCRIPTION,
  REGISTER_ACCESS_CODE,
  REGISTER_CONTRACT,
  REGISTER_PENDING,
  UPDATE_PASSWORD,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  USER_VEHICLES_DETAILS,
  VEHICLES_FORM,
  VEHICLES,
  VEHICLES_DETAILS,
  VEHICLES_ADD,
  VEHICLES_EDIT,
  SETTINGS,
  PAYMENT_SELECT_METHOD,
  SERVICES_SELECT,
  ACCESS_CODE,
  CONTRACT_SEARCH,
  ACCESS_CODE_DETAILS,
  ACCESS_CODE_ADD,
  ADMIN_CONFIGURATION,
  ADMIN_PROFILE,
  ADMIN_LOGS,
  ADMIN_MAILS,
  ADMIN_ORGANIZATION_FORM,
  TEST,
  TEST_GROUP_FORM,
  TEST_LIST,
  TEST_FORM,
  TEST_GROUP_LIST,
  ADMIN,
  ADMIN_ORGANIZATION_LIST,
  ADMIN_GROUP_FORM,
  ADMIN_GROUP_METAS,
  ADMIN_GROUP_LIST,
  ADMIN_PARK_LIST,
  ADMIN_CONTRACT_LIST,
  ADMIN_SERVICE_LIST,
  ADMIN_METAS_LIST,
  ADMIN_NOTES_FORM,
  ADMIN_NOTES_LIST,
  ADMIN_ACCESS_CODE_SEARCH,
  ADMIN_USERS_FORM,
  ADMIN_USERS_LIST,
  ADMIN_CODE_LIST,
  ADMIN_METAS_FORM,
  ADMIN_CODE_IMPORT,
  ADMIN_CODE_FORM,
  ADMIN_SERVICE_FORM,
  ADMIN_FAQ_LIST_GROUP,
  ADMIN_FAQ_LIST,
  ADMIN_FAQ_GROUP_EDIT,
  ADMIN_FAQ_EDIT,
  PPA_ADD,
  MONERIS_PAYPAGE,
  MONERIS_VAULT,
  LAYOUT
} from '@client/actions';
import {EntityState, EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {
  LayoutScreenInfo,
  OrganizationConfigInfo,
  NodeItem,
  AuthCodeInfo,
  Session,
  ContractInfo,
  AccessCodeGroupInfo
} from '@client/utils/shared-constants';
import {getConfigByScope} from "@client/utils/functions";

export interface State {
  isPublicArea: boolean;
  showSidebav: boolean;
  isScope: boolean;
  scopeId: string;
  screen: LayoutScreenInfo;
  scopeConfig: OrganizationConfigInfo;
  enableAdminOptions: boolean;
  isAdminMenu: boolean;
  selectedGroup: string | null;
  selectedItem: string | null;
  admin_menu: {
    [k: string]: NodeItem | null;
  };
  menu: {
    [k: string]: NodeItem | null;
  };
}

export const initialState: State = {
  isPublicArea: true,
  showSidebav: true,
  isScope: false,
  scopeId: 'abonnement',
  screen: {
    desktop: null,
    mobile: null,
    tablet: null
  },
  scopeConfig: {
    enableGateway: true,
    enablePublicRegistration: true,
    enablePrivateRegistration: true,
    disablePublicSubscriptionService: false
  },
  enableAdminOptions: false,
  isAdminMenu: false,
  selectedGroup: null,
  selectedItem: null,
  admin_menu: {
    'profile': {
      'id': 'profile',
      'title': 'Profile',
      'link': '/admin/profile',
      'icon': 'fa fa-address-card',
      'enabled': true,
      'priority': 1,
      'type': 'item',
      'class': ''
    },
    'organization_list': {
      'id': 'organization_list',
      'title': 'Organisations',
      'link': '/admin/organization',
      'icon': 'fa fa-group',
      'enabled': true,
      'priority': 1000,
      'type': 'item',
      'class': ''
    },
    'parks': {
      'id': 'park',
      'title': 'Stationnements',
      'link': '/admin/park-list',
      'icon': 'fa fa-commenting',
      'enabled': true,
      'priority': 1000,
      'type': 'group',
      'class': ''
    },
    'contract': {
      'id': 'contract',
      'title': 'Abonnements',
      'link': '/admin/contract-list',
      'icon': 'fa fa-paperclip',
      'enabled': true,
      'priority': 1000,
      'type': 'group',
      'class': ''
    },
    'service_note': {
      'id': 'service_note',
      'title': 'Services',
      'link': '/admin/service-note',
      'icon': 'fa fa-list',
      'enabled': true,
      'priority': 1000,
      'type': 'group',
      'class': ''
    },
    'faq': {
      'id': 'faq',
      'title': 'FAQs',
      'link': '/admin/faq',
      'icon': 'fa fa-question',
      'enabled': true,
      'priority': 1000,
      'type': 'group',
      'class': ''
    },
    'utilisateur': {
      'id': 'utilisateur',
      'title': 'Utilisateurs',
      'link': '/admin/user',
      'icon': 'fa fa-user',
      'enabled': true,
      'priority': 1000,
      'type': 'group',
      'class': ''
    },
    'Derogation': {
      'id': 'Derogation',
      'title': 'Plaques',
      'link': '/admin/derogations',
      'icon': 'fa fa-id-card',
      'enabled': true,
      'priority': 1000,
      'type': 'group',
      'class': ''
    },
    /*'config': {
      'id': 'config',
      'title': 'Configuration',
      'link': '',
      'icon': 'fa fa-asterisk',
      'enabled': true,
      'priority': 1000,
      'type': 'group',
      'class': '',
      'children': {
        'moneris': {
          'id': 'moneris',
          'title': 'Moneris',
          'link': '/admin/config/moneris',
          'icon': 'fa fa-line-chart',
          'enabled': true,
          'priority': 10000,
          'type': 'item',
          'class': ''
        }, 'language': {
          'id': 'language', 'title': 'language', 'link': '/admin/config/language', 'icon': 'fa fa-language',
          'enabled': true, 'priority': 10000, 'type': 'item', 'class': ''
        }
      }
    },*/
    'mail': {
      'id': 'mail',
      'title': 'Historique courriels',
      'link': '/admin/mails',
      'icon': 'fa fa-line-chart',
      'enabled': true,
      'priority': 10000,
      'type': 'item',
      'class': ''
    }
  },
  menu: {
    'my_contract': {
      'title': 'Mon abonnement',
      'id': 'my_contract',
      'link': '/account/contract/details',
      'icon': 'fa fa-car',
      'enabled': true,
      'priority': 5,
      'type': 'group'
    },
    'create_contract': {
      'title': 'Ajouter un abonnement',
      'id': 'create_contract',
      'link': '/account/contract/create',
      'priority': 5,
      'icon': 'fa fa-plus',
      'enabled': true,
      'type': 'group'
    },
    'create_booking': {
      'title': 'Réservation',
      'id': 'create_booking',
      'link': '/account/booking',
      'priority': 5,
      'icon': 'fa fa-plus',
      'enabled': false,
      'type': 'group'
    },
    'search_contract': {
      'title': 'Rechercher un contrat',
      'id': 'search_contract',
      'link': '/account/contract/search',
      'priority': 10,
      'icon': 'fa fa-search',
      'enabled': true,
      'type': 'group'
    },
    'add_access_code': {
      'title': 'Code d\'acces',
      'id': 'add_access_code',
      'link': '/account/access-code/add',
      'priority': 10,
      'icon': 'fa fa-search',
      'enabled': true,
      'type': 'group'
    },
    'view_contract': {
      'title': 'Mon Contrat',
      'id': 'view_contract',
      'link': '/account/contract/view',
      'priority': 10,
      'icon': 'fa fa-search',
      'enabled': true,
      'type': 'group'
    },
    'faq': {
      'title': 'FAQ',
      'id': 'faq',
      'link': '/account/faq',
      'icon': 'fa fa-question',
      'enabled': true,
      'type': 'group',
      'priority': 800
    },
    'settings': {
      'id': 'settings',
      'title': 'Param\u00E8tres',
      'link': '/account/settings',
      'icon': 'fa fa-gear',
      'enabled': true,
      'type': 'group'
    },
    'calandar': {
      'title': 'Calendrier de perception',
      'id': 'calandar',
      'link': '/account/calendar/view',
      'icon': 'fa fa-calendar',
      'enabled': false,
      'type': 'group'
    },
    'ticket': {
      'id': 'ticket',
      'title': 'Payer avis de r\u00E9clamation',
      'link': '/account/settings',
      'icon': 'fa fa-gear',
      'enabled': true,
      'type': 'group',
      'priority': 800
    },
    "csdm": {
      "title": "Transmettre un rapport",
      "id": "csdm",
      "link": "/account/csdm/view",
      "icon": "fa fa-file",
      "enabled": false,
      "type": "group"
    }
  }
};

export function reducer(state = initialState, action: any): State {
  switch (action.type) {
    case LAYOUT.CLICK:
      return layoutClick(state, action.payload);
    case LAYOUT.SET_SCREEN:
      return layoutSetScreen(state, action.payload);
    case LAYOUT.ADD_NODE:
      return layoutAddNode(state, action.payload);
    case LAYOUT.REMOVE_NODE:
      return layoutRemoveNode(state, action.payload);
    case LAYOUT.ADD_GROUP:
      return layoutAddGroup(state, action.payload);
    case LAYOUT.REMOVE_GROUP:
      return layoutRemoveGroup(state, action.payload);
    case LAYOUT.CLOSE_SIDE_NAV:
      return layoutCloseSideNav(state);
    case LAYOUT.OPEN_SIDE_NAV:
      return layoutOpenSideNav(state);
    case LAYOUT.COLLAPSE_SIDE_NAV:
      return layoutCollapseSideNav(state);
    case LAYOUT.CLICK_DELETE_USER:
      return layoutClickDeleteUser(state);
    case LAYOUT.INIT_PUBLIC:
      return layoutInitPublic(state);
    case LAYOUT.INIT_PRIVATE:
      return layoutInitPrivate(state);
    case LAYOUT.COLLAPSE_ADMIN_OPTIONS:
      return layoutCollapseAdminOptions(state);
    case LAYOUT.CLICK_ADMIN_MENU:
      return layoutClickAdminMenu(state);
    case LAYOUT.CLICK_USER_MENU:
      return layoutClickUserMenu(state);
    case APP.INIT:
      return appInit(state, action.payload);
    case EMAIL_EDIT.INIT:
      return emailEditInit(state);
    case AUTH.INIT:
      return authInit(state, action.payload);
    case SESSION.STATE_CHANGE:
      return sessionStateChange(state, action.payload);
    case FAQ.INIT:
      return faqInit(state);
    case CONTRACT.HYDRATE_SUCCESS:
      return contractHydrateSuccess(state, action.payload);
    case CONTRACT_CANCEL.INIT:
      return contractCancelInit(state);
    case CONTRACT_CREATE.INIT:
      return contractCreateInit(state);
    case CONTRACT_DETAILS.INIT:
      return contractDetailsInit(state);
    case CONTRACT_CONDITIONS_SERVICE.INIT:
      return contractConditionsServiceInit(state);
    case CONTRACT_CONDITIONS_PAYMENT.INIT:
      return contractConditionsPaymentInit(state);
    case PARKS_SELECT.INIT:
      return parksSelectInit(state);
    case CALENDAR.INIT:
      return calendarInit(state);
    case PROFILE.INIT:
      return profileInit(state);
    case PROFILE_DETAILS.INIT:
      return profileDetailsInit(state);
    case PROFILE_EDIT.INIT:
      return profileEditInit(state);
    case SUBSCRIPTION.INIT:
      return subscriptionInit(state);
    case LOGIN.INIT:
      return loginInit(state);
    case REGISTER_EMAIL.INIT:
      return registerEmailInit(state);
    case SUBSCRIPTION_DETAILS.INIT:
      return subscriptionDetailsInit(state);
    case SUBSCRIPTION_EDIT.INIT:
      return subscriptionEditInit(state);
    case REGISTER_SUBSCRIPTION.INIT:
      return registerSubscriptionInit(state);
    case REGISTER_ACCESS_CODE.INIT:
      return registerAccessCodeInit(state);
    case REGISTER_CONTRACT.INIT:
      return registerContractInit(state);
    case REGISTER_PENDING.INIT:
      return registerPendingInit(state);
    case UPDATE_PASSWORD.INIT:
      return updatePasswordInit(state);
    case RESET_PASSWORD.INIT:
      return resetPasswordInit(state);
    case FORGOT_PASSWORD.INIT:
      return forgotPasswordInit(state);
    case USER_VEHICLES_DETAILS.INIT:
      return userVehiclesDetailsInit(state);
    case VEHICLES_FORM.INIT:
      return vehiclesFormInit(state, action.payload);
    case VEHICLES.INIT:
      return vehiclesInit(state);
    case VEHICLES_DETAILS.INIT:
      return vehiclesDetailsInit(state);
    case VEHICLES_ADD.INIT:
      return vehiclesAddInit(state);
    case VEHICLES_EDIT.INIT:
      return vehiclesEditInit(state);
    case SETTINGS.INIT:
      return settingsInit(state);
    case PAYMENT_SELECT_METHOD.INIT:
      return paymentSelectMethodInit(state);
    case SERVICES_SELECT.INIT:
      return servicesSelectInit(state);
    case ACCESS_CODE.INIT:
      return accessCodeInit(state);
    case ACCESS_CODE.HYDRATE_SUCCESS:
      return accessCodeHydrateSuccess(state, action.payload);
    case CONTRACT_SEARCH.INIT:
      return contractSearchInit(state);
    case ACCESS_CODE_DETAILS.INIT:
      return accessCodeDetailsInit(state);
    case ACCESS_CODE_ADD.INIT:
      return accessCodeAddInit(state);
    case ADMIN_CONFIGURATION.INIT:
      return adminConfigurationInit(state);
    case ADMIN_PROFILE.INIT:
      return adminProfileInit(state);
    case ADMIN_LOGS.INIT:
      return adminLogsInit(state);
    case ADMIN_MAILS.INIT:
      return adminMailsInit(state);
    case ADMIN_ORGANIZATION_FORM.INIT:
      return adminOrganizationFormInit(state);
    case TEST.INIT:
      return testInit(state);
    case TEST_GROUP_FORM.INIT:
      return testGroupFormInit(state);
    case TEST_LIST.INIT:
      return testListInit(state);
    case TEST_FORM.INIT:
      return testFormInit(state);
    case TEST_GROUP_LIST.INIT:
      return testGroupListInit(state);
    case ADMIN.INIT:
      return adminInit(state);
    case ADMIN_ORGANIZATION_LIST.INIT:
      return adminOrganizationListInit(state);
    case ADMIN_GROUP_FORM.INIT:
      return adminGroupFormInit(state);
    case ADMIN_GROUP_METAS.INIT:
      return adminGroupMetasInit(state);
    case ADMIN_GROUP_LIST.INIT:
      return adminGroupListInit(state);
    case ADMIN_PARK_LIST.INIT:
      return adminParkListInit(state);
    case ADMIN_CONTRACT_LIST.INIT:
      return adminContractListInit(state);
    case ADMIN_SERVICE_LIST.INIT:
      return adminServiceListInit(state);
    case ADMIN_METAS_LIST.INIT:
      return adminMetasListInit(state);
    case ADMIN_NOTES_FORM.INIT:
      return adminNotesFormInit(state);
    case ADMIN_NOTES_LIST.INIT:
      return adminNotesListInit(state);
    case ADMIN_ACCESS_CODE_SEARCH.INIT:
      return adminAccessCodeSearchInit(state);
    case ADMIN_USERS_FORM.INIT:
      return adminUsersFormInit(state);
    case ADMIN_USERS_LIST.INIT:
      return adminUsersListInit(state);
    case ADMIN_CODE_LIST.INIT:
      return adminCodeListInit(state);
    case ADMIN_METAS_FORM.INIT:
      return adminMetasFormInit(state);
    case ADMIN_CODE_IMPORT.INIT:
      return adminCodeImportInit(state);
    case ADMIN_CODE_FORM.INIT:
      return adminCodeFormInit(state);
    case ADMIN_SERVICE_FORM.INIT:
      return adminServiceFormInit(state);
    case ADMIN_FAQ_LIST_GROUP.INIT:
      return adminFaqListGroupInit(state);
    case ADMIN_FAQ_LIST.INIT:
      return adminFaqListInit(state);
    case ADMIN_FAQ_GROUP_EDIT.INIT:
      return adminFaqGroupEditInit(state);
    case ADMIN_FAQ_EDIT.INIT:
      return adminFaqEditInit(state);
    case PPA_ADD.INIT:
      return ppaAddInit(state);
    case MONERIS_PAYPAGE.INIT:
      return monerisPaypageInit(state);
    case MONERIS_VAULT.INIT:
      return monerisVaultInit(state);
    default:
      return state;
  }
}

export function layoutClick(state: State, payload: NodeItem): State {
  return {
    ...state,
    selectedGroup: payload.type === 'group' && payload.id || state.selectedGroup,
    selectedItem: payload.type === 'item' && payload.id || state.selectedItem,
  };
}

export function layoutSetScreen(state: State, payload: LayoutScreenInfo): State {
  return {...state, screen: payload};
}

export function layoutAddNode(state: State, payload: NodeItem): State {
  return {...state};
}

export function layoutRemoveNode(state: State, payload: NodeItem): State {
  return {...state};
}

export function layoutAddGroup(state: State, payload: NodeItem): State {
  return {...state};
}

export function layoutRemoveGroup(state: State, payload: NodeItem): State {
  return {...state};
}

export function layoutCloseSideNav(state: State): State {
  return {...state, showSidebav: false};
}

export function layoutOpenSideNav(state: State): State {
  return {...state, showSidebav: true};
}

export function layoutCollapseSideNav(state: State): State {
  return {...state, showSidebav: !state.showSidebav};
}

export function layoutClickDeleteUser(state: State): State {
  return state;
}

export function layoutInitPublic(state: State): State {
  return {
    ...state,
    isPublicArea: true,
  };
}

export function layoutInitPrivate(state: State): State {
  return {
    ...state,
    isPublicArea: false
  };
}

export function layoutCollapseAdminOptions(state: State): State {
  return {
    ...state,
    enableAdminOptions: !state.enableAdminOptions,
    isAdminMenu: state.enableAdminOptions ? false : state.isAdminMenu
  };
}

export function layoutClickAdminMenu(state: State): State {
  return {
    ...state,
    isAdminMenu: true
  };
}

export function layoutClickUserMenu(state: State): State {
  return {
    ...state,
    isAdminMenu: false
  };
}

export function appInit(state: State, payload: any): State {
  return {
    ...state,
    isScope: payload.isScope,
    scopeId: payload.scope,
    scopeConfig: payload.config || {}
  };
}

export function emailEditInit(state: State): State {
  return state;
}

export function authInit(state: State, payload: AuthCodeInfo): State {
  return {...state, ...payload};
}

export function sessionStateChange(state: State, payload: Session): State {
  let adminMenu = state.admin_menu;
  const enableAdmin = payload && payload.claims && payload.claims.admin || false;
  let isAdminMenu = state.isAdminMenu;
  if (payload && payload.claims && payload.claims.admin) {
    if (payload.claims.accessLevel === 5 && adminMenu) {
      isAdminMenu = true;
      adminMenu = {
        organization_list: adminMenu.organization_list,
        contract: adminMenu.contract,
        utilisateur: adminMenu.utilisateur,
        mail: adminMenu.mail
      };
    }
  }
  return {
    ...state,
    enableAdminOptions: enableAdmin,
    admin_menu: adminMenu,
    isAdminMenu: isAdminMenu,
    menu: {
      ...state.menu,
      csdm: {
        ...state.menu.csdm,
        enabled: !!getConfigByScope('showSubmitCardHolder') && payload && payload.claims && payload.claims.accessLevel >= 2
      }
    }
  };
  /*
  if (payload.claims.admin) {
      return {
          ...state,
          admin_menu: {
              ...state.admin_menu,
              monitoring: {
                  ...state.admin_menu.monitoring,
                  enabled: true
              },
              test: {
                  ...state.admin_menu.test,
                  enabled: true
              },
              delete_account: {
                  ...state.admin_menu.delete_account,
                  enabled: true
              },
              delete_access_code: {
                  ...state.admin_menu.delete_access_code,
                  enabled: true
              },
              delete_contract: {
                  ...state.admin_menu.delete_contract,
                  enabled: true
              },
              admin: {
                  ...state.admin_menu.admin,
                  enabled: true
              },
              sync_account: {
                  ...state.admin_menu.sync_account,
                  enabled: true
              },
          }
      };
  }
  return {
      ...state,
      admin_menu: {
          ...state.admin_menu,
          monitoring: {
              ...state.admin_menu.monitoring,
              enabled: false
          },
          test: {
              ...state.admin_menu.test,
              enabled: false
          },
          delete_account: {
              ...state.admin_menu.delete_account,
              enabled: false
          },
          delete_access_code: {
              ...state.admin_menu.delete_access_code,
              enabled: false
          },
          delete_contract: {
              ...state.admin_menu.delete_contract,
              enabled: false
          },
          admin: {
              ...state.admin_menu.admin,
              enabled: false
          },
          sync_account: {
              ...state.admin_menu.sync_account,
              enabled: false
          },
      }
  };
  */
}

export function faqInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'faq',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function contractHydrateSuccess(state: State, payload: ContractInfo): State {
  if (!payload) {
    return state;
  }
  let menu = {...state.menu};
  let admin_menu = {...state.admin_menu};

  if(payload.service && payload.service.serviceBillingType === 'AtBooking' && payload.isPaid && payload.subscriptionService && payload.subscriptionService.isApproved) {
    menu = {
      ...menu,
      create_booking: {
        ...menu.create_booking,
        enabled: true
      }
    }
  } else {
    menu = {
      ...menu,
      create_booking: {
        ...menu.create_booking,
        enabled: false
      }
    }
  }

  if (payload.isCreationCompleted) {
    admin_menu = {
      ...admin_menu,
      delete_contract: {
        ...admin_menu.delete_contract,
        enabled: true
      },
    };
    menu = {
      ...menu,
      my_contract: {
        ...menu.my_contract,
        enabled: true
      },
      create_contract: {
        ...menu.create_contract,
        enabled: false
      },
      search_contract: {
        ...menu.search_contract,
        enabled: false
      },
      add_access_code: {
        ...menu.add_access_code,
        enabled: false
      },
    };
  } else {
    admin_menu = {
      ...admin_menu,
      delete_contract: {
        ...admin_menu.delete_contract,
        enabled: false
      },
    };
    menu = {
      ...menu,
      my_contract: {
        ...menu.my_contract,
        enabled: false
      },
      create_contract: {
        ...menu.create_contract,
        enabled: true
      },
      search_contract: {
        ...menu.search_contract,
        enabled: true
      },
      add_access_code: {
        ...menu.add_access_code,
        enabled: true
      },
    };
  }
  if (payload.pdfLink) {
    menu = {
      ...menu,
      view_contract: {
        ...menu.view_contract,
        enabled: true
      },
    };
  } else {
    menu = {
      ...menu,
      view_contract: {
        ...menu.view_contract,
        enabled: false
      },
    };
  }
  return {
    ...state,
    menu: menu,
    admin_menu: admin_menu
  };
}

export function contractCancelInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'my_contract',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function contractCreateInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'create_contract',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function contractDetailsInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'my_contract',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function contractConditionsServiceInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'create_contract',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function contractConditionsPaymentInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'create_contract',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function parksSelectInit(state: State): State {
  return state;
}

export function calendarInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'calandar',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function profileInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'settings',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function profileDetailsInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'settings',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function profileEditInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'settings',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function subscriptionInit(state: State): State {
  return {
    ...state,
    selectedItem: null,
    isAdminMenu: false
  };
}

export function loginInit(state: State): State {
  return state;
}

export function registerEmailInit(state: State): State {
  return state;
}

export function subscriptionDetailsInit(state: State): State {
  return {
    ...state,
    selectedItem: null,
    isAdminMenu: false
  };
}

export function subscriptionEditInit(state: State): State {
  return {
    ...state,
    selectedItem: null,
    isAdminMenu: false
  };
}

export function registerSubscriptionInit(state: State): State {
  return state;
}

export function registerAccessCodeInit(state: State): State {
  return state;
}

export function registerContractInit(state: State): State {
  return state;
}

export function registerPendingInit(state: State): State {
  return state;
}

export function updatePasswordInit(state: State): State {
  return state;
}

export function resetPasswordInit(state: State): State {
  return state;
}

export function forgotPasswordInit(state: State): State {
  return state;
}

export function userVehiclesDetailsInit(state: State): State {
  return state;
}

export function vehiclesFormInit(state: State, payload: string): State {
  return {...state};
}

export function vehiclesInit(state: State): State {
  return {
    ...state,
    selectedItem: null,
    isAdminMenu: false
  };
}

export function vehiclesDetailsInit(state: State): State {
  return {
    ...state,
    selectedItem: null,
    isAdminMenu: false
  };
}

export function vehiclesAddInit(state: State): State {
  return {
    ...state,
    selectedItem: null,
    isAdminMenu: false
  };
}

export function vehiclesEditInit(state: State): State {
  return {
    ...state,
    selectedItem: null,
    isAdminMenu: false
  };
}

export function settingsInit(state: State): State {
  return {
    ...state,
    selectedItem: null,
    isAdminMenu: false
  };
}

export function paymentSelectMethodInit(state: State): State {
  return state;
}

export function servicesSelectInit(state: State): State {
  return state;
}

export function accessCodeInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'add_access_code',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function accessCodeHydrateSuccess(state: State, payload: AccessCodeGroupInfo): State {
  return state;
  /*
      let menu = { ...state.admin_menu };
      if (payload) {
          menu = {
              ...menu,
              delete_access_code: {
                  ...menu.delete_access_code,
                  enabled: true
              },
          };
      }
      else {
          menu = {
              ...menu,
              delete_access_code: {
                  ...menu.delete_access_code,
                  enabled: false
              },
          };
      }
      return {
          ...state,
          admin_menu: menu
      };
      */
}

export function contractSearchInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'search_contract',
    selectedItem: null,
    isAdminMenu: false
  };
}

export function accessCodeDetailsInit(state: State): State {
  return state;
}

export function accessCodeAddInit(state: State): State {
  return state;
}

export function adminConfigurationInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'config',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminProfileInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'profile',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminLogsInit(state: State): State {
  return state;
}

export function adminMailsInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'system',
    selectedItem: 'mail',
    isAdminMenu: true
  };
}

export function adminOrganizationFormInit(state: State): State {
  return state;
}

export function testInit(state: State): State {
  return state;
}

export function testGroupFormInit(state: State): State {
  return state;
}

export function testListInit(state: State): State {
  return state;
}

export function testFormInit(state: State): State {
  return state;
}

export function testGroupListInit(state: State): State {
  return state;
}

export function adminInit(state: State): State {
  return state;
}

export function adminOrganizationListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'organization_list',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminGroupFormInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'organization_list',
    selectedItem: 'organization_group',
    isAdminMenu: true
  };
}

export function adminGroupMetasInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'organization_list',
    selectedItem: 'organization_meta',
    isAdminMenu: true
  };
}

export function adminGroupListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'organization_list',
    selectedItem: 'organization_group',
    isAdminMenu: true
  };
}

export function adminParkListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'park',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminContractListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'contract',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminServiceListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'organization_list',
    selectedItem: 'organization_service',
    isAdminMenu: true
  };
}

export function adminMetasListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'organization_list',
    selectedItem: 'organization_meta',
    isAdminMenu: true
  };
}

export function adminNotesFormInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'service_note',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminNotesListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'service_note',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminAccessCodeSearchInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'access_code',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminUsersFormInit(state: State): State {
  return state;
}

export function adminUsersListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'utilisateur',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminCodeListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'organization_list',
    selectedItem: 'organization_code',
    isAdminMenu: true
  };
}

export function adminMetasFormInit(state: State): State {
  return state;
}

export function adminCodeImportInit(state: State): State {
  return state;
}

export function adminCodeFormInit(state: State): State {
  return state;
}

export function adminServiceFormInit(state: State): State {
  return state;
}

export function adminFaqListGroupInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'faq',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminFaqListInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'faq',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminFaqGroupEditInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'faq',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function adminFaqEditInit(state: State): State {
  return {
    ...state,
    selectedGroup: 'faq',
    selectedItem: null,
    isAdminMenu: true
  };
}

export function ppaAddInit(state: State): State {
  return state;
}

export function monerisPaypageInit(state: State): State {
  return state;
}

export function monerisVaultInit(state: State): State {
  return state;
}
