import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { getSessionClaimsAdmin, getSessionState, getSubscriptionState } from '@client/selectors';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginEmailGuard implements CanActivate {
  constructor(private store: Store<any>, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(getSessionState),
      filter(x => x && x.isLoaded),
      mergeMap(s => {
        const loggedIn = !!s.uid;

        if (!loggedIn) {
          return of(true);
        }
        if (!s.emailVerified) {
          return this.router.navigate(['/auth/register/email/pending']);
        }

        return this.store.pipe(
          select(getSubscriptionState),
          filter(x => x && x.isHydrated),
          withLatestFrom(this.store.pipe(select(getSessionClaimsAdmin))),
          mergeMap(([s, accessLevel]) => {
            const contractNo = (s.data && s.data.spaq && s.data.spaq.contractNo) || null;

            if (contractNo) {
              if (Number(accessLevel) === 5) {
                return this.router.navigate(['/admin/organization']);
              }
              return this.router.navigate(['/account/contract/details'], { queryParams: { from: 10 } });
            }
            return this.router.navigate(['/auth/register/subscription']);
          })
        );
      })
    );
  }
}
